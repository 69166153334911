/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import React, { forwardRef } from 'react';
import { InfoWindow } from '@react-google-maps/api';
import { handleDirections } from '../../../components/office-page/office-page-helper';
import Icon from 'libs/shared/ui-components/src/lib/icon/ba-icon';
import { CmsImage } from 'libs/shared/ui-components/src/lib/cms-image/cms-image';
import { OfficePageProps } from '../../../components/office-page/office-page';
import { ServiceProps } from '../../../components/resources/resources-components/services-list';
import {
  getServiceArray,
  handlePhoneClick,
  renderOfficeAddress,
  shareByMailClick
} from 'apps/bayada/app/find-an-office/office-service';
import VisitOfficeButton from '../../../components/office-page/visit-office-button';
import { serviceDataProps } from 'apps/bayada/app/find-an-office/page';
import { OfficeModel } from '../office-data-parser';
import ServiceLink from './service-link';

export interface MarkerPopupProps {
  office: OfficeModel;
  localOfficePage: OfficePageProps | null;
  setSelectedPlace?: any;
  serviceList: ServiceProps[] | null | undefined;
  isFindAnOfficePage: boolean;
  selectedService?: serviceDataProps;
  isJv?: boolean;
  jvService?: ServiceProps | undefined;
}

/**
 * Component for rendering the popup window when a marker is clicked on the map.
 * @param {MarkerPopupProps} props The props passed to the MarkerPopup component.
 */
const MarkerPopup: React.FC<any> = forwardRef(
  (props: MarkerPopupProps, ref) => {
    const {
      office,
      setSelectedPlace,
      localOfficePage,
      serviceList,
      isFindAnOfficePage,
      selectedService,
      isJv,
      jvService
    } = props || {};

    /**
     * Closes the popup window by setting the selected place to undefined.
     */
    const handleInfoWindowClose = () => {
      setSelectedPlace(undefined);
    };

    React.useImperativeHandle(ref, () => ({
      handleInfoWindowClose: handleInfoWindowClose
    }));

    /**
     * Renders the list of services offered by the office with clickable links.
     * @param {any} office - The office object containing service details.
     * @returns {JSX.Element[]} - Array of JSX elements representing the services offered.
     */
    const renderServicesOffered = (office: any) => {
      let servArr: any[] = [];
      if (isFindAnOfficePage) {
        servArr = office?.serviceArr;
      } else {
        servArr = getServiceArray(office, serviceList);
      }
      return servArr?.map((speciality: any, index: number) => {
        return (
          <div
            key={index}
            className={`flex items-center color-ba-primary-black mb-2 gap-1`}
            aria-label="services-offered-text"
          >
            {speciality?.icon && (
              <CmsImage {...speciality?.icon} width={20} height={20} />
            )}
            <ServiceLink
              className={`underline ${isJv ? 'color-ba-blue-600' : 'text-primary-shade'} cursor-pointer underline-offset-21`}
              speciality={speciality}
            />
          </div>
        );
      });
    };

    return (
      <InfoWindow
        position={{
          lat: office?.mailingAddressLatitude,
          lng: office?.mailingAddressLongitude
        }}
        onCloseClick={handleInfoWindowClose}
      >
        <>
          <div
            className="absolute top-2 right-2 cursor-pointer flex items-center delay-75 duration-300 ease-in-out scale-100 hover:scale-110 will-change-transform"
            onClick={handleInfoWindowClose}
          >
            <Icon iconName="close" className="svg-icon icon-20" />
          </div>
          <div className="bg-white rounded-lg lg:w-auto">
            <div className="py-3">
              <div className="hidden lg:flex items-center justify-center">
                <div className="flex flex-col gap-3 ">
                  <h5 className="t-18-21 font-frutiger font-bold color-ba-primary-black">
                    {office?.name}
                  </h5>
                  <h2 className="t-14-15 font-normal color-ba-gray-900  ">
                    {renderOfficeAddress(office)}
                  </h2>
                  <div className="flex gap-2 t-13">
                    <span className="color-ba-gray-425 t-13">
                      {localOfficePage?.servicesOfferedLabel}
                    </span>
                  </div>
                  <div className="flex gap-2 gap-y-2 flex-wrap empty:hidden">
                    {renderServicesOffered(office)}
                  </div>
                  <div className="my-2 empty:hidden">
                    <VisitOfficeButton
                      buttonVarient="contained"
                      color="primary"
                      office={office}
                      service={
                        isFindAnOfficePage
                          ? selectedService?.fullName
                          : isJv
                            ? jvService?.fullName
                            : localOfficePage?.service?.fullName
                      }
                      text={localOfficePage?.visitOfficeButtonText ?? ''}
                      sx={{
                        padding: '5px',
                        width: '150px'
                      }}
                      isJv={isJv}
                    />
                  </div>
                  {(localOfficePage?.getDirectionsLabel ||
                    localOfficePage?.shareViaEmailLabel) && (
                    <div className="flex gap-4 items-start align-middle py-3 border-t border-solid border-ba-gray-175 empty:hidden">
                      {localOfficePage?.getDirectionsLabel && (
                        <a
                          className="t-13 flex gap-1 outline-offset-1 flex-1 items-start"
                          tabIndex={0}
                          aria-label={localOfficePage?.getDirectionsLabel}
                        >
                          <Icon
                            iconName="locationMarker"
                            className="svg-icon icon-20 flex items-center justify-center color-ba-gray-425"
                          ></Icon>
                          <span
                            className={`color-ba-primary-red underline cursor-pointer ${isJv ? 'color-ba-blue-600' : 'text-primary-shade'} underline-offset-2`}
                            onClick={() => {
                              handleDirections(office);
                            }}
                          >
                            {localOfficePage?.getDirectionsLabel}
                          </span>
                        </a>
                      )}
                      {localOfficePage?.shareViaEmailLabel && (
                        <a
                          className="t-13 flex gap-1 outline-offset-1 flex-1 items-start"
                          tabIndex={0}
                          aria-label="{localOfficePage?.shareViaEmailLabel}"
                        >
                          <Icon
                            iconName="mail"
                            className="svg-icon icon-20 flex items-center justify-center color-ba-gray-425"
                          ></Icon>
                          <span
                            className={`color-ba-primary-red underline underline-offset-2 cursor-pointer ${isJv ? 'color-ba-blue-600' : 'text-primary-shade'}`}
                            onClick={() => shareByMailClick(office)}
                          >
                            {localOfficePage?.shareViaEmailLabel}
                          </span>
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-3 lg:hidden mx-auto">
                <h5 className="t-18-21 font-frutiger font-bold color-ba-primary-black">
                  {office?.name}
                </h5>
                <h2 className="t-14-15 font-normal color-ba-gray-900">
                  {renderOfficeAddress(office)}
                </h2>
                <div className="flex justify-between flex-col md:flex-row md:flex-wrap gap-3 align-middle py-3 border-b lg:border-t border-solid border-ba-gray-95">
                  {office?.phone && (
                    <span
                      className="t-14-15 flex gap-1 w-full md:w-auto border-solid border-b md:border-none pb-4 md:pb-0 border-ba-gray-95"
                      onClick={() => handlePhoneClick(office?.phone)}
                    >
                      <Icon
                        iconName="call"
                        className="svg-icon icon-16 flex items-center justify-center"
                      ></Icon>
                      {office?.phone
                        ?.replace(/\D/g, '')
                        ?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                    </span>
                  )}
                  <div className="flex flex-col md:flex-row gap-4 shrink-0">
                    <span className="t-13 color-ba-gray-425">
                      {office?.distance?.toFixed(2)} miles
                    </span>
                    <div className="flex gap-2 empty:hidden flex-wrap">
                      {localOfficePage?.getDirectionsLabel && (
                        <a
                          className="t-13 flex gap-1 outline-offset-1"
                          tabIndex={0}
                          aria-label={localOfficePage?.getDirectionsLabel}
                        >
                          <Icon
                            iconName="locationPin"
                            className="svg-icon icon-16 flex items-center justify-center"
                          ></Icon>
                          <span
                            className={`color-ba-primary-red ${isJv ? 'color-ba-blue-600' : 'text-primary-shade'} underline underline-offset-2`}
                            onClick={() => {
                              handleDirections(office);
                            }}
                          >
                            {localOfficePage?.getDirectionsLabel}
                          </span>
                        </a>
                      )}
                      {localOfficePage?.shareViaEmailLabel && (
                        <a
                          className="t-13 flex gap-1 outline-offset-1"
                          tabIndex={0}
                          aria-label={localOfficePage?.shareViaEmailLabel}
                        >
                          <Icon
                            iconName="mail"
                            className="svg-icon icon-20 flex items-center justify-center"
                          ></Icon>
                          <span
                            className={`color-ba-primary-red underline underline-offset-2 cursor-pointer ${isJv ? 'color-ba-blue-600' : 'text-primary-shade'}`}
                            onClick={() => shareByMailClick(office)}
                          >
                            {localOfficePage?.shareViaEmailLabel}
                          </span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 t-13 font-semibold mb-3 flex-col lg:flex-row">
                  {localOfficePage?.servicesOfferedLabel && (
                    <span className="color-ba-gray-425">
                      {localOfficePage?.servicesOfferedLabel}
                    </span>
                  )}
                  <div className="flex gap-1 flex-col md:flex-row md:flex-wrap">
                    {renderServicesOffered(office)}
                  </div>
                </div>
                <div className="my-2">
                  <VisitOfficeButton
                    buttonVarient="contained"
                    color="primary"
                    office={office}
                    service={
                      isFindAnOfficePage
                        ? selectedService?.fullName
                        : isJv
                          ? jvService?.fullName
                          : localOfficePage?.service?.fullName
                    }
                    text={localOfficePage?.visitOfficeButtonText ?? ''}
                    sx={{
                      minWidth: '150px'
                    }}
                    isJv={isJv}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </InfoWindow>
    );
  }
);

MarkerPopup.displayName = 'MarkerPopup';

export default MarkerPopup;
