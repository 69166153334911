/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntryCollection, EntrySkeletonType } from 'contentful';
import { homePageUrl } from '../constants';

// Export a function named "slugToArray" which is a function that takes a "slug" parameter and returns an array
export const slugToArray = (slug: string) => {
  if (!slug) {
    return [];
  }
  const slugArray =
    slug !== homePageUrl ? slug?.toLowerCase()?.split('/') : [homePageUrl];
  // Return the slugArray
  return slugArray;
};

// Export a function named constructPageQuery that takes a slug as an argument and returns query object.
export const constructPageQuery = (slug: string) => {
  const slugArray = slugToArray(slug);
  const query: { [key: string]: string | number | string[] | boolean } = {
    content_type: 'page',
    'fields.slug[in]': slug,
    select: ['fields.slug', 'fields.parentPage'],
    order: 'sys.createdAt'
  };
  if (slugArray?.length > 1) {
    query['fields.slug[in]'] = slugArray;
    query['fields.parentPage.sys.contentType.sys.id'] = 'page';
    query['fields.parentPage[exists]'] = true;
  }
  // Return the query object
  return query;
};

// Export a constant named `isPageDataValidAgainstSlug` that is a function that takes two parameters, `slug` and `response` and returns a boolean value.
export const isPageDataValidAgainstSlug = (
  slug: string,
  response: EntryCollection<EntrySkeletonType, undefined, string>
) => {
  const slugArray = slugToArray(slug);
  const slugsAgainstData = new Set<string>([]);
  slugArray?.map(() => {
    response?.items?.forEach((item) => {
      const parentSlug = (item?.fields?.parentPage as any)?.fields?.slug;
      const selfSlug = item?.fields?.slug as string;
      if (parentSlug && parentSlug !== homePageUrl) {
        slugsAgainstData.add(parentSlug).add(selfSlug);
      } else {
        slugsAgainstData.add(selfSlug);
      }
    });
  });
  const slugArrayFromSet = Array.from(slugsAgainstData).join('/');
  console.log(
    slugArrayFromSet === slug,
    ` ::: ${slug} isPageDataValidAgainstSlug`
  );
  // Return a boolean value based on whether the slugs in the Set match the slug string
  return slugArrayFromSet === slug ? true : false;
};

export const removeSpecialCharsAndToLower = (str: string) => {
  return str
    ?.trim()
    ?.toLowerCase()
    ?.replace(/\s+/g, '-')
    ?.replace(/[^\w\s]/gi, '');
};

export const replaceWithOfficeData = (data?: string | null, office?: any) => {
  if (data && office) {
    return data
      ?.replace('{{mailingAddressCity}}', office?.mailingAddressCity)
      ?.replace('{{mailingAddressState}}', office?.mailingAddressState)
      ?.replace('{{name}}', office?.name)
      ?.replace('{{jvName}}', office?.jvName)
      ?.replace('{{phone}}', office?.phone);
  }
};
