'use client';

import { useCallback } from 'react';
import { CmsImage } from '@bayada/shared/ui-components';
import BioPage, {
  BioPageProps
} from 'apps/bayada/components/bio-page/bio-page';
import {
  CmsRichText,
  CmsRichTextProps
} from 'apps/bayada/components/cms-rich-text/cms-rich-text';
import { CmsImageProps } from '@bayada/interfaces';
import ShareBlock, {
  ShareBlockProps
} from 'apps/bayada/components/share-block/share-block';
import { DownloadButton } from './resources-download-button';
import { formatDate } from 'apps/bayada/utils/helper';

export interface PageContentProps {
  title: string;
  author: BioPageProps | null;
  date: string;
  image: CmsImageProps | null;
  contents?: CmsRichTextProps;
  carouselItemCount?: number;
  carouselLabel?: string;
  shareBlock?: ShareBlockProps | null;
  downloadText?: string;
  aboutTheAuthorLabel?: string;
  whitePaperPdf?: WhitePaperPdfProps | null;
}

export interface WhitePaperPdfProps {
  fileUrl: string;
  fileName: string;
  title: string;
}

/* The code provided is a TypeScript React component named `PageContent`. This component takes in a set of props defined by the `PageContentProps` interface and renders a structured page content layout */

export const PageContent: React.FC<PageContentProps> = (props) => {
  const {
    contents,
    title,
    date,
    author,
    shareBlock,
    downloadText = '',
    image,
    aboutTheAuthorLabel = '',
    whitePaperPdf
  } = props || {};

  const renderShareBlock = useCallback(() => {
    if (!shareBlock) return null;

    return <ShareBlock {...shareBlock} />;
  }, [shareBlock]);

  const renderButton = useCallback(() => {
    return (
      <DownloadButton
        isOutlinedButton
        buttonLabel={downloadText}
        whitePaperPdf={whitePaperPdf}
      />
    );
  }, [whitePaperPdf, downloadText]);

  return (
    <div className="page-content pt-4 md:pt-8 lg:w-11/12">
      <div id="rich-text-element">
        {title && (
          <h2
            className="t-30-46 mb-4 font-frutiger font-bold"
            aria-label="title-container"
          >
            {title}
          </h2>
        )}
        {(date || author) && (
          <p
            className="t-16-17 color-ba-gray-425 font-frutiger"
            aria-label="date-author-container"
          >
            {`By ${author?.firstName ? author?.firstName : ''} ${author?.lastName ? author?.lastName : ''} ${author?.designation ? author?.designation : ''} | ${formatDate(date)}`}
          </p>
        )}

        <div
          className="flex sm:items-center my-6 gap-8 sm:gap-4 lg:hidden flex-col sm:flex-row"
          aria-label="top-container"
        >
          <div className="flex">{renderShareBlock()}</div>
          {whitePaperPdf && <div className="sm:ml-auto">{renderButton()}</div>}
        </div>

        <div
          className="ba-hover-expand-card-pic relative aspect-video w-full rounded-lg my-3 md:my-8"
          aria-label="image-container"
        >
          {image && (
            <CmsImage
              {...image}
              className="h-full w-full object-cover rounded-lg"
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </div>

        <div className="py-3">
          {typeof contents !== 'string' && (
            <CmsRichText
              value={contents}
              className="page-content-richtext-block"
            />
          )}
        </div>
      </div>

      <div
        className="flex sm:items-center my-6 gap-8 sm:gap-4 flex-col sm:flex-row py-4 border-y border-solid  border-ba-gray-175"
        aria-label="bottom-container"
      >
        <div className="flex">{renderShareBlock()}</div>
        {whitePaperPdf && <div className="sm:ml-auto">{renderButton()}</div>}
      </div>
      {author && (
        <div className="author-details">
          <h3 className="t-18-21 mb-6 font-frutiger font-bold">
            {aboutTheAuthorLabel}
          </h3>
          <div>{author && <BioPage isArticleBioPage={true} {...author} />}</div>
        </div>
      )}
    </div>
  );
};
