/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import React, { Suspense, useEffect, useState } from 'react';
import {
  ComponentMapTypes,
  PageMapTypes,
  componentMap,
  pageMap
} from '../../mapping';
import get from 'lodash/get';
import { parseComponentData } from '../../utils/component-data-parser';
import { Heading } from '@bayada/shared/ui-components';
import {
  composeCssClassName,
  isDevelopmentEnvironment
} from 'apps/bayada/utils/helper';
import { parsepageData } from 'apps/bayada/utils/page-data-parser';
import { useInView } from 'react-intersection-observer';

export interface ComponentResolverProps {
  data?: any;
  className?: string;
  wrapperContainer?: boolean;
  wrapperClassName?: string;
}

/* This code snippet is a TypeScript React component called `ComponentResolver`. It is a functional component that takes in props of type `ComponentResolverProps` which extends `CommonProps`. The component is responsible for dynamically rendering different components based on the `contentTypeID` extracted from the `data` prop. */

const ComponentResolver: React.FC<ComponentResolverProps> = ({
  data,
  className,
  wrapperClassName,
  wrapperContainer = false
}) => {
  /* The code snippet you provided is related to handling lazy loading of components in a React
functional component called `ComponentResolver` with the `react-intersection-observer` library. Here's a breakdown of what the code is doing: */
  const [ref, isInView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
    rootMargin: '30px 0px'
  });
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (isInView && !hasLoaded) {
      setHasLoaded(true);
    }
  }, [isInView, hasLoaded]);

  // check if development environment to turn on dev flags
  const isDev = isDevelopmentEnvironment();

  /* The code snippet is extracting the `contentTypeID` from the `data` prop using lodash's `_.get` method. It then uses this `contentTypeID` to look up the corresponding component and page mappings in the `componentMap` and `pageMap` objects respectively. */
  const contentTypeID = get(data, 'sys.contentType.sys.id');
  const Component = componentMap[contentTypeID as ComponentMapTypes];
  const isComponent =
    contentTypeID && contentTypeID in componentMap ? true : false;

  /* is assigning a value to the variable `Page` by looking up a specific key in the `pageMap` object based on the `contentTypeID` extracted from the `data` prop. */
  const Page = pageMap[contentTypeID as PageMapTypes];

  if (Component || Page) {
    /* These lines of code are preparing the necessary data for rendering the component or page based on the `contentTypeID` extracted from the `data` prop. */
    const componentClassName = composeCssClassName(contentTypeID);
    const componentData = isComponent
      ? parseComponentData(contentTypeID, data) || {}
      : parsepageData(contentTypeID, data) || {};

    const commonProps = {
      className: `${className}`,
      wrapperClassName: wrapperClassName,
      wrapperContainer: wrapperContainer
    };

    return (
      <>
        <div
          ref={ref}
          className={`${componentClassName ?? ''} ${className ?? ''} component-wrapper`}
        >
          {hasLoaded && (
            <>
              {isDev && (
                <Heading type="h6" className="t-15 bg-black text-white p-1">
                  {contentTypeID}
                </Heading>
              )}
              {Component ? (
                <Suspense>
                  <Component {...commonProps} {...componentData} />
                </Suspense>
              ) : (
                <Suspense>
                  <Page {...commonProps} {...componentData} />
                </Suspense>
              )}
            </>
          )}
        </div>
      </>
    );
  } else {
    console.log(
      `%c ComponentResolver: Component or Page not found for ${contentTypeID}! `,
      'background: #222; color: #bada55'
    );
  }

  return null;
};
// const LazyComponentWithLazyLoad = withLazyLoad(ComponentResolver, 0.5);
export default ComponentResolver;
