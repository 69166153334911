import dynamic from 'next/dynamic';
import { TextCardProps } from './components/text-card/text-card';
import { QuoteProps } from './components/quote/quote';
import { PeoplePageProps } from './components/people/people';
import { BioPageProps } from './components/bio-page/bio-page';
import { TestimonialProps } from './components/testimonial/testimonial';
import { CmsImageProps } from '@bayada/interfaces';
import { CmsTabProps } from './components/cms-tab/cms-tab';
import { HomeHeroBannerProps } from './components/banner/home-hero-banner';
import { AccordionContainerProps } from './components/accordion-container/accordion-container';
import { ItemItemHeadingProps } from './components/item-item-heading/item-item-heading';
import { ImageAndItemProps } from './components/image-and-item/image-and-item';
import { StatsContainerProps } from './components/stats-container/stats-container';
import {
  CmsVideoProps,
  CtaButtonProps,
  SeperatorBlockProps,
  SocialMediaProps,
  AlertProps,
  DonateCtaProps
} from '@bayada/shared/ui-components';
import { MenuItemGroupProps } from './components/menu-item-group/menu-item-group';
import { BannerProps } from './components/banner/banner';
import { TextAndItemProps } from './components/text-and-item/text-and-item';
import { TextBlockProps } from './components/text-block/text-block';
import { NavigationLinkProps } from './components/navigation-link/navigation-link';
import { TextAndImageProps } from './components/text-and-image/text-and-image';
import { HubSpotFormProps } from './components/hub-spot-form/cms-hub-spot-form';
import { ResourcesListProps } from './components/resources/resources-list/resources-list';
import { ArticlesProps } from './components/resources/types/articles/articles';
import { CaseStudiesProps } from './components/resources/types/case-study/case-study';
import { ShareBlockProps } from './components/share-block/share-block';
import { FindCareProps } from './components/find-care/find-care';
import { NewsProps } from './components/resources/types/news/news';
import { PartnerSuccessStoryProps } from './components/resources/types/partner-success-story/partner-success-story';
import { WhitePapersProps } from './components/resources/types/white-papers/white-papers';
import { DynamicCarouselProps } from './components/dynamic-carousel/dynamic-carousel';
import { CarouselContainerProps } from './components/static-carousel-container/static-carousel-container';
import { RequestServiceProps } from './components/request-service/request-service';
import { ContentGridProps } from './components/content-grid/content-grid';
import { NotFoundProps } from './components/404/not-found';
import { CmsFooterProps } from './components/footer/footer';
import { CmsHeaderProps } from './components/navigation-header/navigation-header';
import { NavigationDrawerProps } from './components/navigation-header/navigation-drawer/navigation-drawer';
import { PageTemplateProps } from './components/page-template/page-template';

/* This code is defining a `componentMap` object in TypeScript, which maps different component
names to dynamically imported components using the `dynamic` function. Each component is imported
asynchronously using `import()` statements. The components are imported from various paths within
the project as well as from external packages like `@bayada/shared/ui-components`. The components
are then assigned to their respective keys in the `componentMap` object with their corresponding
props types. This approach allows for lazy loading of components, improving performance by only
loading components when they are needed. */

export const componentMap = {
  textAndItem: dynamic<TextAndItemProps>(() =>
    import('./components/text-and-item/text-and-item').then(
      (module) => module.TextAndItem
    )
  ),
  textBlock: dynamic<TextBlockProps>(() =>
    import('./components/text-block/text-block').then(
      (module) => module.TextBlock
    )
  ),
  image: dynamic<CmsImageProps>(() =>
    import('@bayada/shared/ui-components').then((module) => module.CmsImage)
  ),
  video: dynamic<CmsVideoProps>(() =>
    import('@bayada/shared/ui-components').then((module) => module.CmsVideo)
  ),
  banner: dynamic<BannerProps>(() =>
    import('./components/banner/banner').then((module) => module.Banner)
  ),
  cta: dynamic<CtaButtonProps>(() =>
    import('@bayada/shared/ui-components').then((module) => module.CtaButton)
  ),
  socialMedia: dynamic<SocialMediaProps>(() =>
    import('@bayada/shared/ui-components').then((module) => module.SocialMedia)
  ),
  alert: dynamic<AlertProps>(() =>
    import('@bayada/shared/ui-components').then((module) => module.Alert)
  ),
  menuitemGroup: dynamic<MenuItemGroupProps>(() =>
    import('./components/menu-item-group/menu-item-group').then(
      (module) => module.MenuItemGroup
    )
  ),
  separatorBlock: dynamic<SeperatorBlockProps>(() =>
    import('@bayada/shared/ui-components').then(
      (module) => module.SeperatorBlock
    )
  ),
  statsContainer: dynamic<StatsContainerProps>(() =>
    import('./components/stats-container/stats-container').then(
      (module) => module.StatsContainer
    )
  ),
  imageAndItem: dynamic<ImageAndItemProps>(() =>
    import('./components/image-and-item/image-and-item').then(
      (module) => module.ImageAndItem
    )
  ),
  textAndImage: dynamic<TextAndImageProps>(() =>
    import('./components/text-and-image/text-and-image').then(
      (module) => module.TextAndImage
    )
  ),
  itemItemWithHeading: dynamic<ItemItemHeadingProps>(() =>
    import('./components/item-item-heading/item-item-heading').then(
      (module) => module.ItemItemHeading
    )
  ),
  accordionContainer: dynamic<AccordionContainerProps>(() =>
    import('./components/accordion-container/accordion-container').then(
      (module) => module.AccordionContainer
    )
  ),
  homeHeroBanner: dynamic<HomeHeroBannerProps>(() =>
    import('./components/banner/home-hero-banner').then(
      (module) => module.HomeHeroBanner
    )
  ),
  tab: dynamic<CmsTabProps>(() =>
    import('./components/cms-tab/cms-tab').then((module) => module.CmsTab)
  ),
  contentGrid: dynamic<ContentGridProps>(() =>
    import('./components/content-grid/content-grid').then(
      (module) => module.ContentGrid
    )
  ),
  carouselContainer: dynamic<CarouselContainerProps>(() =>
    import(
      './components/static-carousel-container/static-carousel-container'
    ).then((module) => module.StaticCarouselContainer)
  ),
  bioPage: dynamic<BioPageProps>(() =>
    import('./components/bio-page/bio-page').then((module) => module.BioPage)
  ),
  people: dynamic<PeoplePageProps>(() =>
    import('./components/people/people').then((module) => module.People)
  ),
  testimonialBlock: dynamic<TestimonialProps>(() =>
    import('./components/testimonial/testimonial').then(
      (module) => module.Testimonial
    )
  ),
  quote: dynamic<QuoteProps>(() =>
    import('./components/quote/quote').then((module) => module.Quote)
  ),
  textCard: dynamic<TextCardProps>(() =>
    import('./components/text-card/text-card').then((module) => module.TextCard)
  ),
  navigationLink: dynamic<NavigationLinkProps>(() =>
    import('./components/navigation-link/navigation-link').then(
      (module) => module.NavigationLink
    )
  ),
  hubspotForm: dynamic<HubSpotFormProps>(() =>
    import('./components/hub-spot-form/cms-hub-spot-form').then(
      (module) => module.CmsHubSpotForm
    )
  ),
  socialMediaCarousel: dynamic(() =>
    import('./components/social-media-carousel/social-media').then(
      (module) => module.SocialMedia
    )
  ),
  shareBlock: dynamic<ShareBlockProps>(() =>
    import('./components/share-block/share-block').then(
      (module) => module.ShareBlock
    )
  ),
  dynamicCarousel: dynamic<DynamicCarouselProps>(() =>
    import('./components/dynamic-carousel/dynamic-carousel').then(
      (module) => module.DynamicCarousel
    )
  ),
  donationsCta: dynamic<DonateCtaProps>(() =>
    import('@bayada/shared/ui-components').then((module) => module.DonateCta)
  ),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  findCare: dynamic<FindCareProps>(() =>
    import('./components/find-care/find-care').then((module) => module.FindCare)
  ),
  requestService: dynamic<RequestServiceProps>(() =>
    import('./components/request-service/request-service').then(
      (module) => module.RequestService
    )
  ),
  defaultErrorPage: dynamic<NotFoundProps>(() =>
    import('./components/404/not-found').then((module) => module.NotFound)
  )
};

export type ComponentMapTypes = keyof typeof componentMap;

/* The `pageMap` object in the provided TypeScript code is mapping different page components to their respective dynamic imports. This page's components differ from those in `componentMap`. It will include the components from `componentMap` as well as additional UI elements rendered with the properties of paemap. Each key in the `pageMap` object represents a specific type of page component, such as resource listing, articles, case studies, news, partner success stories, and white papers. */

export const pageMap = {
  resourceListing: dynamic<ResourcesListProps>(() =>
    import('./components/resources/resources-list/resources-list').then(
      (module) => module.ResourcesList
    )
  ),
  articles: dynamic<ArticlesProps>(() =>
    import('./components/resources/types/articles/articles').then(
      (module) => module.Articles
    )
  ),
  caseStudy: dynamic<CaseStudiesProps>(() =>
    import('./components/resources/types/case-study/case-study').then(
      (module) => module.CaseStudies
    )
  ),
  news: dynamic<NewsProps>(() =>
    import('./components/resources/types/news/news').then(
      (module) => module.News
    )
  ),

  partnerSuccessStory: dynamic<PartnerSuccessStoryProps>(() =>
    import(
      './components/resources/types/partner-success-story/partner-success-story'
    ).then((module) => module.PartnerSuccessStory)
  ),

  whitepapers: dynamic<WhitePapersProps>(() =>
    import('./components/resources/types/white-papers/white-papers').then(
      (module) => module.WhitePapers
    )
  )
};
export type PageMapTypes = keyof typeof pageMap;

export const Footer = dynamic<CmsFooterProps>(() =>
  import('./components/footer/footer').then((module) => module.Footer)
);

export const NavigationHeader = dynamic<CmsHeaderProps>(() =>
  import('./components/navigation-header/navigation-header').then(
    (module) => module.NavigationHeader
  )
);

export const NavigationDrawer = dynamic<NavigationDrawerProps>(() =>
  import(
    './components/navigation-header/navigation-drawer/navigation-drawer'
  ).then((module) => module.NavigationDrawer)
);

export const PageTemplate = dynamic<PageTemplateProps>(() =>
  import('./components/page-template/page-template').then(
    (module) => module.PageTemplate
  )
);
