/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { usePathname } from 'next/navigation';
import Container from '@mui/material/Container';
import { isLocalOfficeLocationUrl } from 'apps/bayada/utils/helper';
import { GoogleMapApiKey } from 'apps/bayada/constants';
import { serviceDataProps } from 'apps/bayada/app/find-an-office/page';
import Breadcrumb from '../breadcrumb/breadcrumb';

interface BreadcrumbProps {
  office?: any;
  serviceList?: serviceDataProps[];
}

/* This code defines a functional component named `BreadCrumbs`. Inside the component:
1. It uses the `usePathname` hook from the `next/navigation` library to get the current pathname.
2. It then splits the pathname by '/' and filters out any empty strings to get an array of path
names.
3. It conditionally renders a JSX structure based on the length of the `pathNames` array:
   - If the length of `pathNames` is greater than 1, it renders a `<div>` element with specific
styling.
   - Inside the `<div>`, it renders a `<Container>` component from the Material-UI library with a
`<Breadcrumb>` component passing the `pathNames` array as `breadcrumbData`. */
const BreadCrumbs = (props: BreadcrumbProps) => {
  const { office, serviceList } = props || {};
  const paths = usePathname();
  const urlForCheck = paths?.substring(1);
  const isLocationPage = isLocalOfficeLocationUrl(urlForCheck);
  const pathNames = paths?.split('/').filter((path) => path);
  const GoogleMapsApiKey = GoogleMapApiKey;
  return (
    <>
      {pathNames?.length > 1 && (
        <div className="w-full pb-2">
          <Container fixed className="relative mx-auto">
            {pathNames?.length > 1 && (
              <Breadcrumb
                breadcrumbData={pathNames}
                isLocationPage={isLocationPage}
                googleMapsApiKey={GoogleMapsApiKey}
                office={office}
                serviceList={serviceList}
              />
            )}
          </Container>
        </div>
      )}
    </>
  );
};

export default BreadCrumbs;
