/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import {
  GoogleMapApiKey,
  directionUrl,
  OfficeApibaseURL,
  bayadaOfficeLocationTitle
} from 'apps/bayada/constants';
import { ServiceProps } from 'apps/bayada/components/resources/resources-components/services-list';
import { contentfulClient } from 'apps/bayada/config/contentful-client';

/**
 * Fetches office locations based on the provided latitude, longitude, proximity, and optional services.
 * @param {number | (() => number) | undefined} lat - The latitude coordinate of the location.
 * @param {number | (() => number) | undefined} long - The longitude coordinate of the location.
 * @param {string | undefined} proximity - The proximity range for office search.
 * @param {string[] | undefined} services - Optional services to filter the search results.
 * @returns {Promise<any>} - A Promise that resolves with the response data containing office locations.
 */
export const getOfficeLocations = async (
  lat: number | (() => number) | undefined,
  long: number | (() => number) | undefined,
  proximity: string | undefined,
  baseURL?: string
) => {
  const instance = axios.create({
    baseURL: baseURL ? baseURL : OfficeApibaseURL
  });
  if (lat && long) {
    const response = await instance.get(
      `/v1/OfficeData/${lat}/${long}/${proximity}`
    );
    if (response?.data?.ResponseCode !== 200) {
      throw new Error('Network response was not ok');
    }
    return response?.data;
  }
};

/**
 * Generates an array of service objects based on the provided office services and service list.
 *
 * The function filters and processes the services associated with the given office object to produce
 * a structured array of service details. It avoids duplicates and highlights the current service if specified.
 *
 * @param office - An object representing the office, which includes a `services` property that is an array of service identifiers (strings).
 * @param serviceList - An optional array of service objects. Each object should have properties `shortName`, `fullName`, `icon`, and `masterDataId`.
 *                      The `shortName` is used to match with the office's service identifiers.
 * @param currentService - An optional string representing the name of the current service. This will be used to flag the service as the current one in the result array.
 *
 * @returns An array of service objects. Each object contains:
 *   - `fullName`: The full name of the service.
 *   - `icon`: The icon associated with the service.
 *   - `isCurrentService`: A boolean indicating if the service's `fullName` matches the `currentService` (ignoring case and removing non-word characters).
 *   - `masterDataId`: The unique identifier of the service.
 *
 * The function ensures that duplicate services (based on `masterDataId`) are not included in the resulting array.
 */
export const getServiceArray = (
  office: any,
  serviceList?: ServiceProps[] | null,
  currentService?: string
) => {
  const servArr: any[] = [];
  office?.services?.forEach((ser: string) => {
    const reqItem = serviceList?.find((el: ServiceProps) => {
      const shortName = el?.shortName?.replace(/\s+/g, '')?.toLowerCase();
      if (ser && shortName) {
        return ser
          ?.replace(/\s+/g, '')
          ?.toLowerCase()
          .includes(shortName?.toLowerCase());
      }
      return false;
    });
    const duplicateServ = servArr?.some(
      (item) => item.masterDataId === reqItem?.masterDataId
    );
    if (reqItem && !duplicateServ) {
      servArr?.push({
        fullName: reqItem?.fullName,
        icon: reqItem?.icon,
        isCurrentService: currentService
          ? reqItem?.fullName?.replace(/[^\w\s]/g, ' ')?.toLowerCase() ===
            currentService?.toLowerCase()
          : false,
        masterDataId: reqItem?.masterDataId
      });
    }
  });
  return servArr;
};

/**
 * Opens the default mail client with a pre-filled email to share office location details.
 * @param {any} officeLoc - The office location object containing details like name, address, phone, etc.
 */
export const shareByMailClick = (officeLoc: any) => {
  const subject = encodeURIComponent(
    `${officeLoc?.name} ${bayadaOfficeLocationTitle}`
  );
  const body = encodeURIComponent(
    `${officeLoc?.name || ''}\n${officeLoc?.mailingAddress1 || ''}\n${officeLoc?.mailingAddress2 || ''}\n${officeLoc?.mailingAddressCity || ''}, ${officeLoc?.mailingAddressState || ''} ${officeLoc?.mailingAddressZipCode || ''}${officeLoc?.phone ? `\nPhone: ${officeLoc.phone.replace(/\D/g, '')?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}` : ''}`
  );
  const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;
  if (window) {
    window.open(mailtoUrl, '_blank');
  }
};

/**
 * Retrieves the latitude and longitude coordinates for a given address using the Google Maps Geocoding API.
 * @param {string | null} address - The address for which to retrieve coordinates.
 * @param {string | undefined} apiKey - The API key for accessing the Google Maps Geocoding API.
 * @returns {Promise<any>} - A Promise that resolves to the response containing the latitude and longitude coordinates.
 */
export const getLocLatLong = async (
  address: string | null | undefined,
  apiKey: string | undefined
) => {
  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`
  );
  return res?.json();
};

/**
 * Opens the default maps app to provide directions from the origin to the specified destination.
 * @param {string} origin - The origin address or coordinates.
 * @param {string} lat - The latitude of the destination.
 * @param {string} lng - The longitude of the destination.
 */
export const goToDirections = (origin: string, lat: string, lng: string) => {
  const directionsUrl = directionUrl
    ?.replace('{origin}', origin)
    ?.replace('{destinationLat}', lat)
    ?.replace('{destinationLng}', lng);
  if (window) {
    window?.open(directionsUrl);
  }
};

/**
 * Opens the default phone app to make a call.
 * @param {string} phone - The phone number to call.
 */
export const handlePhoneClick = (phone: string) => {
  if (window) {
    window.open(`tel:${phone}`, '_self');
  }
};

/**
 * Constructs a URL route string based on the provided office details and optional service.
 *
 * The function creates a formatted URL path for an office location, incorporating the office's mailing address and an optional service.
 * The URL is constructed to be lowercased, sanitized (removing non-alphanumeric characters), and formatted with hyphens for spaces.
 *
 * @param office - An object representing the office, which includes various address components and an `officeId`:
 *   - `mailingAddressState`: The state portion of the office's mailing address.
 *   - `mailingAddressCity`: The city portion of the office's mailing address.
 *   - `mailingAddress1`: The primary street address.
 *   - `mailingAddress2`: An additional street address (if applicable).
 *   - `officeId`: A unique identifier for the office.
 * @param service - An optional string representing a service associated with the office. This will be included in the route if provided.
 *
 * @returns A string representing the constructed URL route. The format of the route is:
 *   - `/service/locations/state/city/street/officeId`
 *
 * The service and address components are formatted to be URL-friendly:
 * - Leading and trailing spaces are removed.
 * - All characters are converted to lowercase.
 * - Non-alphanumeric characters are replaced with spaces.
 * - Spaces are replaced with hyphens.
 */
export const constructRoute = (office: any, service?: string) => {
  const _service = service
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/g, ' ')
    ?.replace(/\s/g, '-');
  const _state = office?.mailingAddressState
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');

  const _city = office?.mailingAddressCity
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');

  const _street = (
    office?.mailingAddress1?.trim() +
    ' ' +
    office?.mailingAddress2?.trim()
  )
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');
  return `/${_service}/locations/${_state}/${_city}/${_street}/${office?.officeId}`;
};

/**
 * Checks if an address string is valid for use in an autocomplete field.
 *
 * This function verifies if the provided address string is considered valid based on its length.
 * Specifically, it checks if the address is not just whitespace and contains at least one non-whitespace character.
 *
 * @param address - A string representing the address to be validated.
 *
 * @returns A boolean value:
 *   - `true` if the address string is not empty and contains more than just whitespace.
 *   - `false` if the address string is empty or consists only of whitespace.
 *
 * The function trims leading and trailing whitespace from the address before performing the length check.
 */
export const isAutoCompleteFieldValid = (address: string) => {
  if (address?.trim()?.length > 0) {
    return true;
  }
  return false;
};

/**
 * Constructs a URL for finding an office based on the provided location details.
 *
 * This function generates a URL to navigate to a "find an office" page. It determines the appropriate address to use based on whether a location was entered manually or a predefined address was selected. If a location is provided, it uses a geocoding service to retrieve the formatted address. If no location is entered, it uses the selected address directly. The resulting URL includes the address and optionally the selected service, formatted for use in a query string.
 *
 * @param enteredLoc - An optional string representing the manually entered location. If provided, this location is used to fetch the formatted address via a geocoding service.
 * @param selectedAddress - An optional string representing a predefined address. This is used directly if `enteredLoc` is not provided.
 * @param selectedService - An optional string representing the selected service to be included in the URL. If provided, it will be included in the query string.
 *
 * @returns A promise that resolves to a URL string for the "find an office" page. The URL includes:
 *   - `address`: The formatted address, sanitized for inclusion in a query string (spaces replaced with '+', and special characters removed).
 *   - `service`: The selected service, if provided, with spaces replaced with '+'.
 *
 * If neither `enteredLoc` nor `selectedAddress` is provided, the function will not generate a URL.
 *
 * Note: This function assumes that `getLocLatLong` is an asynchronous function that retrieves location details from a geocoding service.
 */
export const goToFindOffice = async (
  enteredLoc?: string | null,
  selectedAddress?: string,
  selectedService?: string
) => {
  let address;
  if (enteredLoc) {
    const response = await getLocLatLong(enteredLoc, GoogleMapApiKey);
    address = response?.results?.[0]?.formatted_address ?? null;
  } else {
    address = selectedAddress;
  }

  if (address) {
    const findOfficeUrl = `/find-an-office?address=${address?.replace(/[#\\/\s]/g, '+')}&service=${selectedService?.replace(/ /g, '+')}`;
    return findOfficeUrl;
  }
};

/**
 * The function `getOfficeById` fetches office data by ID and handles errors appropriately.
 * @param {string} id - The `id` parameter in the `getOfficeById` function is a string that represents
 * the unique identifier of the office you want to retrieve information for.
 * @returns The function `getOfficeById` is returning the data fetched from the specified API endpoint
 * for the office with the given `id`. If the fetch operation is successful and the response is ok, it
 * returns the JSON data received from the API. If there is an error during the fetch operation or if
 * the response is not ok, it will log the error to the console.
 */
export const getOfficeById = async (id: string) => {
  if (id) {
    try {
      const instance = axios.create({
        baseURL: OfficeApibaseURL
      });
      const officeRes = await instance.get(
        OfficeApibaseURL + `/v1/OfficeData/GetByOfficeId/${id}`
      );
      if (officeRes.data?.ResponseCode !== 200) {
        throw new Error('Network response was not ok');
      }
      const data = await officeRes?.data;

      return data;
    } catch (error) {
      console.log(` ${id} :: getOfficeById error -->`, error);
    }
  } else {
    console.log(`${id} is null --- getOfficeById`);
  }
};

/* The function `getLicenseList` fetches office License Entries from contentful.*/
export const getLicenseList = async () => {
  try {
    const contentful = contentfulClient();
    const licensesListResponse = await contentful?.getEntries({
      content_type: 'officeLicense',
      include: 10
    });
    const data = licensesListResponse?.items.map((list: any) => {
      return list.fields;
    });
    return data;
  } catch (error) {
    console.log('no licenses added');
  }
};

/* This function removes initial and trailing commas and spaces */
export const trimCommasAndSpaces = (text?: string | null) => {
  if (text) {
    return text?.trim()?.replace(/^[,\s]+|[,\s]+$/g, '');
  }
  return '';
};

/* This function removes initial and trailing spaces */
export const trimSpaces = (text?: string | null) => {
  if (text) {
    return text?.trim();
  }
  return '';
};

/**
 * Generates a URL-friendly slug based on the provided service name.
 *
 * This function converts a service name into a slug suitable for use in a URL. It transforms the service name to lowercase, replaces spaces with hyphens, and replaces slashes with ampersands to ensure the slug is valid and readable in URLs.
 *
 * @param service - An optional string representing the service name to be converted into a slug. If provided, it will be processed to create the slug.
 *
 * @returns A string representing the URL-friendly slug derived from the service name. The slug is formatted as follows:
 *   - Converted to lowercase.
 *   - Spaces replaced with hyphens ('-').
 *   - Slashes ('/') replaced with ampersands ('&').
 *
 * If the `service` parameter is not provided, the function will return `undefined`.
 */
export const getServicePageSlug = (service?: string) => {
  if (service) {
    const slug = `${service
      ?.toLowerCase()
      ?.replace(/\s+/g, '-')
      ?.replace(/\//g, '&')}`;
    return slug;
  }
};

/**
 * Constructs a formatted address string from the given office object.
 *
 * This function combines various components of the office's mailing address into a single, formatted string. It concatenates the address lines, city, state, and ZIP code, ensuring that commas and spaces are trimmed from each component. The address components are added in the following order: mailing address line 1, mailing address line 2 (if present), city, state, and ZIP code.
 *
 * @param office - An object representing the office, which includes optional properties for the mailing address components:
 *   - `mailingAddress1`: The first line of the office's mailing address.
 *   - `mailingAddress2`: The second line of the office's mailing address (if applicable).
 *   - `mailingAddressCity`: The city of the office's mailing address.
 *   - `mailingAddressState`: The state of the office's mailing address.
 *   - `mailingAddressZipCode`: The ZIP code of the office's mailing address.
 *
 * @returns A string representing the formatted mailing address. The components are concatenated with commas separating them. If a component is missing, it is skipped. The final address string will not end with a trailing comma.
 *
 * The function uses `trimCommasAndSpaces` to clean up each address component by removing leading and trailing commas and spaces.
 */
export const renderOfficeAddress = (office: any) => {
  return (
    (office?.mailingAddress1
      ? trimCommasAndSpaces(office?.mailingAddress1) + ', '
      : '') +
    (office?.mailingAddress2
      ? trimCommasAndSpaces(office?.mailingAddress2) + ', '
      : '') +
    (office?.mailingAddressCity
      ? trimCommasAndSpaces(office?.mailingAddressCity) + ', '
      : '') +
    (office?.mailingAddressState
      ? trimCommasAndSpaces(office?.mailingAddressState) + ', '
      : '') +
    (office?.mailingAddressZipCode
      ? trimCommasAndSpaces(office?.mailingAddressZipCode)
      : '')
  );
};
