/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, SxProps, Theme } from '@mui/material';
import { useAppContext } from 'apps/bayada/context/app-context';
import { constructRoute } from 'apps/bayada/app/find-an-office/office-service';
import { useRouter } from 'next/navigation';
import React from 'react';

export type VisitOfficeButtonProps = {
  buttonVarient: 'contained' | 'outlined';
  color: 'primary' | 'secondary';
  text: string;
  office: { [key: string]: any };
  service?: string;
  sx?: SxProps<Theme> | undefined;
  isJv?: boolean;
};

const VisitOfficeButton: React.FC<VisitOfficeButtonProps> = (props) => {
  const {
    buttonVarient = 'contained',
    office,
    color = 'primary',
    service,
    sx,
    text = '',
    isJv
  } = props || {};
  const router = useRouter();
  const { context } = useAppContext();
  const { userAgent } = context || {};

  const goToDetailsPage = () => {
    if (office && service) {
      const route = constructRoute(office, service);
      if (userAgent === 'Firefox') {
        window.location.href = route;
      } else {
        router?.push(route);
      }
    }
  };

  let buttonColor = color;
  let style = { backgroundColor: '' };
  if (isJv) {
    buttonColor = 'secondary';
    style = {
      backgroundColor: 'var(--ba-blue-600)'
    };
  }

  return (
    <>
      <Button
        className="w-full xs:w-auto py-2 px-4"
        variant={buttonVarient}
        color={buttonColor}
        disableFocusRipple
        onClick={(e) => {
          e?.preventDefault();
          goToDetailsPage();
        }}
        sx={sx}
        style={style}
      >
        <span className="t-15 font-frutiger font-medium leading-snug capitalize">
          {text}
        </span>
      </Button>
    </>
  );
};

export default VisitOfficeButton;
