import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Icon } from '@bayada/shared/ui-components';
import { Sys } from '@bayada/interfaces';

export type TagsProp = {
  tags: ArticleTag[];
  title?: string;
  isRadioGroup?: boolean;
  showMoreLabel?: string;
  onTagSelect?: (tag: ArticleTag | null) => void;
};

export type ArticleTag = {
  articleTag: string;
  sys?: Sys;
  entryId?: string;
};

/**
 * @description - This is a functional  component that renders a list of article tags as radio buttons or simple text, with an optional "Show More" button.
 *
 * @param {ArticleTag[]} tags - An array of ArticleTag objects to display.
 * @param {string} title - The title of the tag list. Defaults to 'Tags'.
 * @param {boolean} isRadioGroup - A flag that determines whether to render tags as radio button. Defaults to false.
 * @param {(tag:ArticleTag | null) => void} onTagSelect - A callback function to be called when a tag is selected.
 * @param {string} [showMoreLabel] - The label for the "Show more" button. Defaults to 'Show more'.
 *
 * @returns - The JSX element to render.
 */
const ArticleTags: React.FC<TagsProp> = ({
  tags,
  title = 'Tags',
  isRadioGroup = false,
  onTagSelect,
  showMoreLabel = 'show more'
}) => {
  const [selectedTag, setSelectedTag] = useState<ArticleTag | null>(null);
  const [itemsToShow, setItemsToShow] = useState<number>(5);

  /**
   * Handle the selection of a tag.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event triggered by selecting a tag.
   * @returns {void}
   */
  const handleTagSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedTagId = event?.target?.value;
    const newSelectedTag =
      tags?.find((tag) => tag?.sys?.id === newSelectedTagId) || null;
    setSelectedTag(newSelectedTag);
    if (onTagSelect) {
      onTagSelect(newSelectedTag);
    }
  };

  /**
   * Handles the click event to show more items button.
   * If tags exist, updates the number of items to show to the total number of tags.
   */
  const handleShowMoreClick = () => {
    if (tags) {
      setItemsToShow(tags?.length); // Updates the number of items to show
    }
  };

  const renderRadioControl = useCallback(() => {
    return (
      <Radio
        icon={
          <Icon
            iconName=""
            className="svg-icon icon-24 rounded-full border border-solid border-gray-300 bg-white"
          ></Icon>
        }
        checkedIcon={
          <div className="icon-24 flex items-center justify-center rounded-full bg-ba-green-450 p-[1px] border-ba-green-450">
            <Icon
              iconName=""
              className="svg-icon icon-20 rounded-[50%] border-[3px] border-solid border-white bg-ba-green-450"
            ></Icon>
          </div>
        }
      />
    );
  }, []);

  return (
    <>
      <div className={`grid-cols-2 md:grid ${!isRadioGroup ? 'pb-4' : ''}`}>
        {title && (
          <h2
            className="t-16-17 mt-4 font-frutiger font-bold  mb-4"
            aria-label="tags-title-container"
          >
            {title}
          </h2>
        )}
      </div>
      {isRadioGroup ? (
        <FormControl
          sx={{
            '&.MuiFormControl-root': {
              paddingLeft: '5px !important'
            }
          }}
        >
          <RadioGroup
            value={selectedTag?.sys?.id || ''}
            onChange={handleTagSelection}
            aria-label={'article-tags-radio-button-group'}
            name={'article-tags-radio-button-group'}
            className="service-radio"
          >
            <FormControlLabel
              value={''}
              control={renderRadioControl()}
              aria-label={`radio-all-button`}
              label={'All'}
            />
            {tags?.map((item: ArticleTag, key: number) =>
              key < itemsToShow ? (
                <FormControlLabel
                  key={key}
                  value={item?.sys?.id || ''}
                  control={renderRadioControl()}
                  aria-label={`radio-button-${item?.articleTag ?? ''}`}
                  label={item?.articleTag}
                  tabIndex={0}
                />
              ) : null
            )}
          </RadioGroup>
          {itemsToShow !== tags?.length && (
            <button
              aria-label={'show-more-services-button'}
              onClick={handleShowMoreClick}
              className="mt-2 flex items-center justify-start border-none t-13  font-semibold color-ba-primary-red underline cursor-pointer"
            >
              {showMoreLabel}
            </button>
          )}
        </FormControl>
      ) : (
        <div className="flex flex-wrap gap-6 w-full">
          {tags?.map((item: ArticleTag, key: number) => (
            <div key={key} className="t-14 font-normal text-primary-black">
              {item?.articleTag}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ArticleTags;
