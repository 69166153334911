import { goToDirections } from 'apps/bayada/app/find-an-office/office-service';

/**
 * Handles the action to get directions to a specific office location.
 * @param {Object} office - The office location details.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleDirections = (office?: any) => {
  const destination = {
    lat: office?.mailingAddressLatitude,
    lng: office?.mailingAddressLongitude
  };
  const origin = '';
  goToDirections(origin, destination?.lat, destination?.lng);
};

export const toTitleCase = (str: string) => {
  return str.toLowerCase().replace(/(?:^|\s)\w/g, (match) => {
    return match.toUpperCase();
  });
};
