/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import {
  Carousel,
  CarouselSlide,
  CmsImage,
  CtaButton,
  WrapperContainer
} from '@bayada/shared/ui-components';
import { Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
// import { contentfulClient } from 'apps/bayada/config/contentful-client';
import { parseDynamicCarouselApiData } from 'apps/bayada/utils/component-data-parser';
import { EntryCollection, EntrySkeletonType } from 'contentful';
import CarouselWrapper from '../static-carousel-container/carousel-wrapper';
import {
  articlesCarouselProps,
  servicesCarouselProps
} from '../static-carousel-container/carousel-constants';
import { usePathname } from 'next/navigation';
import { formatDate } from 'apps/bayada/utils/helper';
import { SortOptions } from '@bayada/interfaces';
import { useAppContext } from 'apps/bayada/context/app-context';
import { MarkDown } from '@bayada/shared/ui-components';

/** Function: DynamicCarousel
 * Overall, this component dynamically fetches data from a CMS API, processes it,
 * and renders it in the form of a carousel based on the provided props.
 */

export type DynamicCarouselProps = {
  type:
    | 'partner success stories'
    | 'news'
    | 'articles'
    | 'whitepapers'
    | 'case studies'
    | 'all';
  services: any[];
  tags: any;
  appearance?: 'normal' | 'text on image';
  numberOfSlides?: number;
  sortBy?: SortOptions;
  env: string;
};

export type DynamicCarouselApiProps = {
  slug: string;
  recommendedCarouselItemCount: number;
  recommendedCarouselLabel: string;
  services: {
    fullName: string;
    masterDataId: number;
    shortName: string;
  };
  tags: string[];
  tagsLabel: string;
  imageAndItem1?: any;
  thumbnailImage: any;
  title: string;
  aboutTheAuthorLabel?: string;
  date?: string;
  shareBlock?: any;
  author?: any;
  updatedAt: string;
  overview: string;
  readMoreAriaLabel: string;
  readMoreButtonText: string;
  readMoreIcon: any;
};

export function DynamicCarousel(props: DynamicCarouselProps) {
  const paths = usePathname();
  const pathNames: string[] = paths?.split('/')?.filter((path) => path);
  const { context } = useAppContext();
  const bg = 'bg-transparent';
  const allTypes: { [key: string]: string } = {
    'partner success stories': 'partnerSuccessStory',
    news: 'news',
    articles: 'articles',
    whitepapers: 'whitepapers',
    'case studies': 'caseStudy'
  };
  const [dynamicCarouselData, setDynamicCarouselData] = useState<
    DynamicCarouselApiProps[]
  >([]);

  useEffect(() => {
    getPageData();
  }, []);

  /*API Data Fetching: The getPageData function is defined to fetch data from the CMS API using the cmsApi function.
   * The fetched data is then processed and stored in the state using setDynamicCarouselData. */
  const cmsApi = (
    type: string,
    tags: string,
    services: string
  ): Promise<EntryCollection<EntrySkeletonType, undefined, string>> => {
    // const isDraftMode = sessionStorage?.getItem('isEnabled');
    const contentful = context?.ctfClient;

    const query: any = {
      content_type: 'page',
      'fields.slug[ne]': pathNames?.[pathNames?.length - 1] ?? undefined,
      'fields.templateReference.sys.contentType.sys.id': type,
      select: ['fields.templateReference', 'fields.slug'],
      include: 10
    };
    if (tags) {
      query['fields.templateReference.fields.tags.sys.id[in]'] = tags;
    }
    if (services) {
      query['fields.templateReference.fields.services.sys.id[in]'] = services;
    }
    return contentful?.getEntries(query);
  };

  /*CMS Api for fetching data based on props.type */
  const getPageData = async () => {
    const tagsIds = props?.tags?.map((item: any) => item?.entryId)?.join(',');
    const serviceIds = props?.services?.map((item) => item?.entryId)?.join(',');
    let finalData: any[] = [];
    if (props?.type === 'all') {
      const promises = Object.values(allTypes)?.map((item: any) =>
        cmsApi(item, tagsIds, serviceIds)
      );
      const data = await Promise.all(promises);
      data?.map((item) => {
        if (item?.items?.length) {
          item?.items.forEach((x) => {
            finalData.push(parseDynamicCarouselApiData(x));
          });
        }
      });
    } else {
      const data = await cmsApi(allTypes[props?.type], tagsIds, serviceIds);
      if (data?.items?.length) {
        data.items.forEach((item) => {
          finalData.push(parseDynamicCarouselApiData(item));
        });
      }
    }
    finalData = sortAndLimitPageData(
      finalData,
      props?.numberOfSlides,
      props?.sortBy
    );
    setDynamicCarouselData(finalData);
  };

  /**Sorting and Limiting Data: The sortAndLimitPageData function sorts the fetched data based on the specified sorting criteria (sortBy)
   * Limits the number of slides to be displayed (numberOfSlides). */
  const sortAndLimitPageData = (
    data: any[],
    numberOfSlides = 12,
    sortBy = 'latest'
  ): any[] => {
    let result = [...data];
    if (sortBy === 'latest') {
      result = result.sort((a: any, b: any) => {
        const dateA: any = new Date(a.updatedAt);
        const dateB: any = new Date(b.updatedAt);
        return dateB - dateA;
      });
    } else if (sortBy === 'oldest') {
      result = result.sort((a: any, b: any) => {
        const dateA: any = new Date(a.updatedAt);
        const dateB: any = new Date(b.updatedAt);
        return dateA - dateB;
      });
    } else if (sortBy === 'a to z') {
      result = result.sort((a: any, b: any) =>
        String(a.title)?.localeCompare(String(b.title))
      );
    } else if (sortBy === 'z to a') {
      result = result.sort((a: any, b: any) =>
        String(b.title)?.localeCompare(String(a.title))
      );
    }
    return result.slice(0, numberOfSlides);
  };

  /*Common Cta button for items based on item data*/
  const CustomCtaButton = useCallback(
    (data: DynamicCarouselApiProps, className: string, sxData: any) => {
      return (
        <CtaButton
          buttonType={'hyperlink'}
          ariaLabel={data?.readMoreAriaLabel ?? 'read label'}
          buttonSize={'regular'}
          buttonText={data?.readMoreButtonText ?? 'Read'}
          url={data?.slug}
          target={'same tab'}
          iconPosition={'after text'}
          icon={data?.readMoreIcon}
          className={className}
          sx={sxData}
        />
      );
    },
    []
  );

  const NormalCarousel = useCallback(() => {
    return (
      <div className={`overflow-hidden ${bg}`} aria-label="articles-carousal">
        <WrapperContainer
          wrapper={true}
          className={`relative mx-auto py-12 lg:pb-20`}
        >
          <div className="mx-auto w-full ba-slider-wrapper">
            <CarouselWrapper cssClass="overflow-carousel">
              <Carousel
                role="region"
                aria-live="polite"
                aria-label="articles-carousal"
                {...articlesCarouselProps}
              >
                {dynamicCarouselData?.map((data, index: number) => {
                  const image = data?.thumbnailImage;
                  return (
                    <CarouselSlide className="md:max-w-lg" key={index}>
                      <Stack
                        role="group"
                        aria-roledescription="slide"
                        className="news-cards grow bg-white overflow-hidden rounded-lg border-solid border-[var(--ba-gray-95)] border"
                        direction="column"
                        spacing={2}
                      >
                        {image && (
                          <div
                            role="img"
                            aria-label="thumbnail image"
                            className="pb-[56.25%] h-0 relative img-expansion"
                          >
                            <figure className="absolute top-0 left-0 w-full h-full rounded-t-lg overflow-hidden">
                              <CmsImage
                                {...image}
                                style={{ width: '100%', height: '100%' }}
                                className="object-cover"
                              />
                              <div className="swiper-lazy-preloader"></div>
                            </figure>
                          </div>
                        )}
                        <div
                          className="flex flex-col p-4 !mt-0 grow"
                          aria-label="carousel-contents"
                        >
                          {data?.services && (
                            <div
                              className="block mb-3"
                              aria-label="short-name-container"
                            >
                              <span className="block t-13 font-semibold color-ba-gray-900">
                                {data?.services?.shortName}
                              </span>
                            </div>
                          )}
                          {data?.title && (
                            <h3
                              className="t-18-21 mb-3 placeholder-title text-primary-black font-frutiger set-frutiger  !font-bold cut-text-image-description"
                              aria-label="thumbnail-title-container"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: MarkDown(data?.title)
                                }}
                              />
                            </h3>
                          )}
                          <div className="inline-block empty:hidden mb-3">
                            {data?.author && (
                              <div
                                className="inline-block inline-data"
                                aria-label="author-container"
                              >
                                <p className="t-15 font-inter color-ba-gray-900">
                                  {data?.author?.fields?.firstName}
                                  {data?.author?.fields?.lastName}
                                </p>
                              </div>
                            )}
                            {data?.date && (
                              <div
                                className="inline-block inline-data"
                                aria-label="date-container"
                              >
                                <p className="t-15 font-inter color-ba-gray-900 leading-normal">
                                  {formatDate(data?.date)}
                                </p>
                              </div>
                            )}
                          </div>
                          {data?.overview && (
                            <div
                              className="mb-4"
                              aria-label="dynamic-overview-container"
                            >
                              <p className="t-15 font-inter color-ba-gray-900 leading-normal cut-text-image-description">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: MarkDown(data?.overview)
                                  }}
                                />
                              </p>
                            </div>
                          )}

                          {data?.slug && (
                            <div className="mt-auto">
                              {CustomCtaButton(data, 'color-ba-primary-red', {
                                padding: '0',
                                color: 'var(--white)',
                                background: 'transparent',
                                border: 'none',
                                textAlign: 'left',
                                justifyContent: 'start',
                                boxShadow: 'none',
                                '.MuiButton-endIcon': {
                                  transition: 'transform ease 0.2s',
                                  willChange: 'transform',
                                  width: 20
                                },

                                '&:hover, &:focus': {
                                  background: 'transparent',
                                  border: 'none',
                                  boxShadow: 'none',
                                  '.MuiButton-endIcon': {
                                    transform: 'translate(5px, 0px)'
                                  }
                                },
                                '&:focus': {
                                  outline: 'solid 2px var(--ba-primary-black)',
                                  outlineOffset: '13px'
                                }
                              })}
                            </div>
                          )}
                        </div>
                      </Stack>
                    </CarouselSlide>
                  );
                })}
              </Carousel>
            </CarouselWrapper>
          </div>
        </WrapperContainer>
      </div>
    );
  }, [dynamicCarouselData]);

  const TextOnImageCarousel = useCallback(() => {
    return (
      <div className={`overflow-hidden ${bg}`} aria-label="text-on-image">
        <WrapperContainer
          wrapper={true}
          className={`relative mx-auto py-12 lg:pb-20`}
        >
          <div className="mt-14">
            <div className="ba-slider-wrapper overflow-carousel">
              <CarouselWrapper cssClass="overflow-carousel">
                <Carousel
                  role="region"
                  aria-live="polite"
                  aria-label="articles-carousal"
                  {...servicesCarouselProps}
                >
                  {dynamicCarouselData?.map((item, index: number) => {
                    const image = item.thumbnailImage;
                    return (
                      <CarouselSlide key={index}>
                        <Stack
                          role="group"
                          aria-roledescription="slide"
                          className="w-full rounded-lg"
                          direction="column"
                          spacing={2}
                        >
                          <div className="!mt-0 h-0 overflow-hidden relative pt-[95%]">
                            {image && (
                              <div
                                role="img"
                                aria-label="thumbnail image with text"
                                className="absolute top-0 left-0 w-full h-full rounded-lg overflow-hidden case-study-block"
                              >
                                <CmsImage
                                  {...image}
                                  style={{ width: '100%', height: '100%' }}
                                  className="object-cover !h-full"
                                />
                              </div>
                            )}
                            <div className="absolute bottom-0 left-0 w-full p-6">
                              {item?.services?.shortName && (
                                <div className="block mb-3">
                                  <div className="py-[5px] px-2 inline-block bg-black rounded text-white t-13 font-semibold leading-none">
                                    <span
                                      className="line-clamp-2"
                                      aria-label="text-on-image-short-name"
                                    >
                                      {item?.services?.shortName}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {item?.title && (
                                <div className="mb-3 block">
                                  <h4
                                    className="t-18-21 font-bold font-frutiger set-frutiger text-white line-clamp-3"
                                    aria-label="text-on-image-title"
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: MarkDown(item?.title)
                                      }}
                                    />
                                  </h4>
                                </div>
                              )}
                              <div
                                className="block"
                                aria-label="text-on-image-button"
                              >
                                {item.slug &&
                                  CustomCtaButton(item, 'icon-btn-movement', {
                                    padding: '0',
                                    color: 'var(--white)',
                                    background: 'transparent',
                                    border: 'none',
                                    boxShadow: 'none',
                                    textAlign: 'left',
                                    justifyContent: 'start',
                                    '.MuiButton-endIcon': {
                                      transition: 'transform ease 0.2s'
                                    },

                                    '&:hover, &:focus': {
                                      background: 'transparent',
                                      color: 'var(--white)',
                                      border: 'none',
                                      boxShadow: 'none',
                                      '.MuiButton-endIcon': {
                                        transform: 'translate(5px, 0px)'
                                      }
                                    },
                                    '&:focus': {
                                      outline: 'solid 2px var(--white)',
                                      outlineOffset: '13px'
                                    }
                                  })}
                              </div>
                            </div>
                          </div>
                        </Stack>
                      </CarouselSlide>
                    );
                  })}
                </Carousel>
              </CarouselWrapper>
            </div>
          </div>
        </WrapperContainer>
      </div>
    );
  }, [dynamicCarouselData]);

  return props?.appearance === 'text on image' ? (
    <TextOnImageCarousel />
  ) : (
    <NormalCarousel />
  );
}

export default DynamicCarousel;
