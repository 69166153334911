import {
  CmsImage,
  CtaButton,
  DisableAnimationsMobile
} from '@bayada/shared/ui-components';
import { AnimatePresence, m, LazyMotion, domAnimation } from 'framer-motion';
import { ResourceItem, ResourcesTypes } from './resources-list';
import { DownloadButton } from '../resources-components/resources-download-button';
import { WhitePapersProps } from '../types/white-papers/white-papers';
import { useCallback } from 'react';
import { formatDate } from 'apps/bayada/utils/helper';

export interface ResourcesListCardsProps {
  resourcesList: ResourceItem[];
}

/**
 * @description - Render a list of resource cards based on the provided resources list.
 *
 * @param {ResourceItem[]} resourcesList -  The list of resource objects to display as cards. Defaults to an empty array.
 * @returns - The JSX element to render.
 */
const ResourcesListItem: React.FC<ResourcesListCardsProps> = ({
  resourcesList = []
}) => {
  // Callback function which render the overview content.
  const renderResourceOverView = useCallback((item: ResourceItem) => {
    if (!item?.resource?.overview) return null;

    return (
      <h2 className="t-14-15  font-normal leading-snug color-ba-gray-900 ">
        {item?.resource?.overview}
      </h2>
    );
  }, []);

  // Callback function which will render the full name of author and date.
  const renderAuthorAndDate = useCallback((item: ResourceItem) => {
    const {
      firstName,
      lastName = '',
      designation = ''
    } = item?.resource?.author ?? {};
    const date = (item?.resource as WhitePapersProps)?.date || '';

    if (!firstName) return null;

    return (
      <p className="t-15 color-ba-gray-900 font-inter">
        {`By ${firstName} ${lastName} ${designation} | ${formatDate(date)}`}
      </p>
    );
  }, []);

  // Callback function which will render the content after the title of the card.
  const renderCardSubContent = useCallback(
    (type: ResourcesTypes, item: ResourceItem) => {
      switch (type) {
        case 'whitepapers':
        case 'news':
          return renderAuthorAndDate(item);
        case 'partner success stories':
        case 'case studies':
          return renderResourceOverView(item);
        case 'articles':
          return (
            <>
              {renderAuthorAndDate(item)}
              {renderResourceOverView(item)}
            </>
          );
        default:
          return null;
      }
    },
    [renderAuthorAndDate, renderResourceOverView]
  );

  // Callback function which render the download button only when the resource type is 'whitepapers'.
  const renderDownloadButton = useCallback(
    (type: ResourcesTypes, item: ResourceItem) => {
      if (!item || type !== 'whitepapers') return null;

      const { whitePaperPdf, downloadText } =
        item?.resource as WhitePapersProps;

      if (!whitePaperPdf) return null;

      return (
        <DownloadButton
          buttonLabel={downloadText ?? 'Download'}
          whitePaperPdf={whitePaperPdf}
          className={'color-ba-primary-red'}
        />
      );
    },
    []
  );

  return (
    <div className={`grid grid-cols-1 lg:grid-cols-3 gap-6 `}>
      <AnimatePresence mode="wait">
        <LazyMotion features={domAnimation}>
          {resourcesList?.map((item, index) => (
            <DisableAnimationsMobile key={index}>
              <m.div
                viewport={{ once: true }}
                transition={{
                  duration: 0.9,
                  ease: 'backInOut'
                }}
                whileInView={{
                  opacity: 1,
                  transform: 'translate(0px , 0px)'
                }}
                initial={{
                  opacity: '0',
                  transform: 'translate(0 , 20px)'
                }}
                key={index}
                className="news-cards mb-0 border border-solid rounded-lg border-ba-gray-175 overflow-hidden flex flex-col gap-2 content-start hover:shadow-gray-300 hover:shadow-md duration-100 transition-shadow img-expansion will-change-[opacity]"
              >
                <m.figure
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.9,
                    ease: 'backInOut'
                  }}
                  whileInView={{
                    filter: 'blur(0px)'
                  }}
                  initial={{
                    filter: 'blur(5px)'
                  }}
                  className="news-cards-pic w-full min-w-0 overflow-hidden !rounded-none will-change-[filter]"
                >
                  {item?.resource?.thumbnailImage && (
                    <CmsImage
                      {...item?.resource?.thumbnailImage}
                      style={{ width: '100%', height: '100%' }}
                      className="object-cover"
                    />
                  )}
                </m.figure>
                <div className="p-4 news-cards-content gap-3 !mt-0 w-full min-w-0 grow">
                  {item?.resource?.services?.fullName && (
                    <h2
                      className="t-13 font-semibold color-ba-gray-900"
                      aria-label="resource-name"
                    >
                      {item?.resource?.services?.fullName}
                    </h2>
                  )}
                  {item?.resource?.title && (
                    <h2
                      className="t-18-21 font-frutiger font-bold line-clamp-2 "
                      aria-label="resource-title"
                    >
                      {item?.resource?.title}
                    </h2>
                  )}
                  {item?.type && renderCardSubContent(item?.type, item)}
                  <div className="flex justify-between mt-auto">
                    <CtaButton
                      buttonType={'hyperlink'}
                      ariaLabel={
                        item?.resource?.readMoreAriaLabel ?? 'read label'
                      }
                      buttonSize={'medium'}
                      buttonText={item?.resource?.readMoreButtonText ?? 'Read'}
                      url={item?.slug}
                      target={'same tab'}
                      iconPosition={'after text'}
                      icon={item?.resource?.readMoreIcon}
                      className={'color-ba-primary-red'}
                    />
                    {item?.type && renderDownloadButton(item?.type, item)}
                  </div>
                </div>
              </m.div>
            </DisableAnimationsMobile>
          ))}
        </LazyMotion>
      </AnimatePresence>
    </div>
  );
};

export default ResourcesListItem;
