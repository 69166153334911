import {
  SocialMedia,
  SocialMediaProps,
  WrapperContainer,
  getWrapperProps
} from '@bayada/shared/ui-components';

export interface ShareBlockProps {
  internalName: string;
  title: string;
  shareList: SocialMediaProps[] | null;
  isVertical?: boolean;
}

/* This code snippet is defining a React functional component called `ShareBlock` that takes in props of type `ShareBlockProps`. The component renders a container div with a title and a list of social media icons based on the `shareList` prop. The component also conditionally applies CSS classes based on the `isVertical` prop to style the layout vertically or horizontally. */

export function ShareBlock(props: ShareBlockProps) {
  const { title, shareList, internalName, isVertical = false } = props || {};

  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);

  return (
    <WrapperContainer
      wrapper={wrapperContainer}
      className="relative mx-auto py-10 sm:py-12 md:py-14"
    >
      <div
        className={`flex ${isVertical ? 'flex-col gap-y-2' : 'flex-row items-center'} lg:ml-auto`}
        role="region"
        aria-label={`${internalName}-container`}
      >
        {title && (
          <h4
            className={`t-16-17 font-semibold text-primary-black mr-3 ${isVertical ? 'mb-4' : ''}`}
          >
            {title}
          </h4>
        )}
        {shareList && (
          <div className="flex gap-2">
            {shareList?.map((item: SocialMediaProps, key: number) => (
              <SocialMedia key={key} {...item} isShareBlock={true} />
            ))}
          </div>
        )}
      </div>
    </WrapperContainer>
  );
}

export default ShareBlock;
