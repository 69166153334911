/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import PropTypes from 'prop-types';
import './cms-rich-text.scss';
import ComponentResolver from '../component-resolver/component-resolver';
import { WhitePaperPdfProps } from '../resources/resources-components/resources-page-content';
import { DownloadButton } from '../resources/resources-components/resources-download-button';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export type CmsRichTextProps = {
  value: Document | unknown | any;
  className?: string;
  styles?: React.CSSProperties;
  font?: 'font-inter' | 'font-frutiger' | 'string';
  isJv?: boolean;
};

/**
 * The code defines a React component called CmsRichText that renders rich text content from
 * Contentful with various styling options.
 * @property {Document | unknown} value - The `value` property in the `CmsRichText` component is used
 * to pass the contentful document or unknown data that needs to be rendered as rich text. This data
 * can include various types of content such as paragraphs, headings, lists, tables, embedded assets,
 * etc. The component uses
 * @property {string} className - The `className` property in the `CmsRichText` component is used to
 * apply custom CSS classes to the rendered rich text content. It allows you to style the component
 * according to your design requirements by passing a string of CSS class names.
 * @property styles - The `styles` property in the `CmsRichTextProps` type is used to define inline CSS
 * styles for the component. It accepts a `React.CSSProperties` object, which allows you to specify CSS
 * properties as key-value pairs. These styles will be applied to the outer container of the `
 * @property {'font-inter' | 'font-frutiger' | 'string'} font - The `font` property in the
 * `CmsRichTextProps` type is used to specify the font style to be applied to the rendered rich text
 * content. It can have one of the following values:
 */

export const CmsRichText: React.FC<CmsRichTextProps> = ({
  value,
  className,
  styles,
  font,
  isJv
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  /**
   * The function `getCodeContent` is used to extract the content of a code block from the
   * rich text content. The function takes in an object `code` and recursively navigates
   * through its children until it finds the actual code content. The function returns the
   * extracted code content.
   *
   * @param {any} code - The `code` parameter is the code block object from the rich text
   * content.
   * @returns {any} The returned value is the extracted code content from the code block.
   */
  const getCodeContent = (code: any) => {
    let textCode = code;
    while (textCode) {
      if (typeof textCode === 'string' || Array.isArray(textCode)) {
        break;
      } else if (textCode?.props?.children) {
        textCode = textCode?.props?.children;
      } else {
        break;
      }
    }
    return textCode;
  };

  const handleCtaClick = (nodeData: any) => {
    const file = nodeData?.file?.fields?.file;
    if (file && file?.url) {
      fetchFile(file?.url);
    }
  };

  function fetchFile(url: any) {
    fetch(url)
      .then((res) => res.blob())
      .then((file) => {
        const tempUrl = URL.createObjectURL(file);
        const aTag = document.createElement('a');
        aTag.href = tempUrl;
        aTag.download = url.replace(/^.*[\\/]/, '');
        document.body.appendChild(aTag);
        aTag.click();
        URL.revokeObjectURL(tempUrl);
        aTag.remove();
      });
  }

  /**
   * The function `hasCodeText` is used to determine whether the given rich text content
   * contains code block or not. The function takes in a JSX object as parameter and recursively
   * navigates through its children until it finds the actual text content. If the text content is
   * found and it is not a string or array, then it means that the rich text content contains
   * code block. The function returns a boolean value indicating whether the code block was
   * found or not.
   * @param {any} content - The `content` parameter is the rich text content that is passed to the
   * function from the parent component.
   * @returns {boolean} The returned value is a boolean indicating whether the code block was
   * found or not.
   */
  const hasCodeText = (content: any): boolean => {
    let text = content;
    let isCode = false;
    while (text) {
      if (typeof text === 'string' || Array.isArray(text)) {
        break;
      } else if (
        typeof text?.props === 'object' &&
        text?.props?.dangerouslySetInnerHTML
      ) {
        isCode = true;
        break;
      } else if (typeof text?.props === 'object' && text?.props?.children) {
        text = text?.props?.children;
      } else {
        break;
      }
    }
    return isCode;
  };
  const removeAdjacentBrElements = (children: any) => {
    children?.forEach((child: any, index: number) => {
      if (
        child?.type === 'br' &&
        ((index > 0 && children[index - 1]?.type === 'a') ||
          (index < children?.length - 1 && children[index + 1]?.type === 'a'))
      ) {
        children.splice(index, 1);
      }
    });
    return children;
  };
  const [playerRef, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false
  });
  const playerStatus = () => {
    if (inView && !isVideoPlaying) {
      setIsVideoPlaying(true);
    }
  };

  const pauseStatus = () => {
    if (inView) {
      setIsVideoPlaying(false);
    }
  };
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (inView && isVideoPlaying) {
        video.play();
      } else {
        video.pause();
      }
    }
  }, [inView]);
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: any) =>
        text?.type === 'br' ? <br /> : hasCodeText(text) ? text : <b>{text}</b>,
      [MARKS.ITALIC]: (text: any) =>
        text?.type === 'br' ? <br /> : hasCodeText(text) ? text : <i>{text}</i>,
      [MARKS.UNDERLINE]: (text: any) =>
        text?.type === 'br' ? <br /> : hasCodeText(text) ? text : <u>{text}</u>,
      [MARKS.SUPERSCRIPT]: (text: any) =>
        text?.type === 'br' ? (
          <br />
        ) : hasCodeText(text) ? (
          text
        ) : (
          <sup>{text}</sup>
        ),
      [MARKS.SUBSCRIPT]: (text: any) =>
        text?.type === 'br' ? (
          <br />
        ) : hasCodeText(text) ? (
          text
        ) : (
          <sub>{text}</sub>
        ),
      [MARKS.CODE]: (text: any) => {
        if (text?.type === 'br') {
          return <br />;
        }
        const codeContent = Array.isArray(text)
          ? text?.filter((element) => typeof element === 'string')?.join('')
          : text;
        const formattedCode = getCodeContent(codeContent);
        return <code dangerouslySetInnerHTML={{ __html: formattedCode }} />;
      }
    },
    renderText: (text: string) => {
      if (!text) {
        return <br />;
      }
      return text
        ?.split('\n')
        ?.flatMap((text, i) => (i > 0 ? [<br key={i} />, text] : text));
    },

    renderNode: {
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        return (
          <a
            href={node?.data?.uri}
            className={`${isJv && `color-ba-blue-600`}`}
          >
            {children}
          </a>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
        const url = node?.data?.target?.fields?.file?.url;
        return (
          <a
            href={url}
            target="_blank"
            className={`${isJv && `color-ba-blue-600`}`}
          >
            {children}
          </a>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
        const data: any = node?.data?.target?.fields;
        const type: string = node?.data?.target?.sys?.contentType?.sys?.id;
        if (type == 'cta')
          return (
            <a
              href={data?.url}
              target={data?.target == 'New tab' ? '_blank' : '_self'}
              aria-label={data?.ariaLabel}
              className={`${isJv && `color-ba-blue-600`}`}
              onClick={() => handleCtaClick(data)}
            >
              {data?.buttonText}
            </a>
          );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
        const url: string = node?.data?.target?.fields?.file?.url;
        const fileName: string = node?.data?.target?.fields?.file?.fileName;
        const assetType: string = node?.data?.target?.fields?.file?.contentType;
        const title: string = node?.data?.target?.fields?.title;
        const prefix = assetType.split('/')[0];
        switch (prefix) {
          case 'audio':
            return (
              <div>
                <div className="color-ba-gray-900">{title}</div>
                <audio controls className=" my-4 w-full sm:min-w-[300px] ">
                  <source src={url} type={assetType} />
                </audio>
              </div>
            );

          case 'video':
            return (
              <div className=" my-4">
                <div className="color-ba-gray-900">{title}</div>
                <div
                  className="w-full  flex pb-[56.25%] h-0 relative overflow-hidden"
                  ref={playerRef}
                >
                  <video
                    ref={videoRef}
                    className="absolute top-0 left-0 w-full h-full focus:outline-1 focus-within:outline-1 outline-offset-2"
                    controls
                    onPlay={playerStatus as any}
                    onPause={pauseStatus as any}
                  >
                    <source src={url} type={assetType} />
                  </video>
                </div>
              </div>
            );
          case 'image':
            return (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                src={url}
                alt={fileName}
                className="mt-4 mb-4  mr-4 inline-block object-contain"
              />
            );
          case 'application':
          case 'text': {
            const document: WhitePaperPdfProps = {
              fileUrl: url,
              fileName: fileName,
              title: title
            };
            return (
              <div className="my-4 sm:mr-4 sm:inline-block w-full sm:w-auto">
                <DownloadButton
                  buttonLabel={title}
                  whitePaperPdf={document}
                  className={`w-full ${isJv && `color-ba-blue-600`}`}
                />
              </div>
            );
          }
          default:
            return null;
        }
      },
      [BLOCKS.HEADING_1]: (node: any, children: any) => {
        const updatedChildren = removeAdjacentBrElements(children);
        return updatedChildren?.length === 1 &&
          updatedChildren?.[0]?.type === 'code' ? (
          <code {...updatedChildren[0]?.props} />
        ) : (
          <h2
            className={`${font ? font : 'font-frutiger'} ${className ?? ''} font-bold color-ba-primary-black mb-6`}
          >
            {updatedChildren}
          </h2>
        );
      },
      [BLOCKS.HEADING_2]: (node: any, children: any) => {
        const updatedChildren = removeAdjacentBrElements(children);
        return updatedChildren?.length === 1 &&
          updatedChildren?.[0]?.type === 'code' ? (
          <code {...updatedChildren[0].props} />
        ) : (
          <h2
            className={`${font ? font : 'font-frutiger'} ${className ?? ''} font-bold color-ba-primary-black mb-6`}
          >
            {updatedChildren}
          </h2>
        );
      },
      [BLOCKS.HEADING_3]: (node: any, children: any) => {
        const updatedChildren = removeAdjacentBrElements(children);
        return updatedChildren?.length === 1 &&
          updatedChildren?.[0]?.type === 'code' ? (
          <code {...updatedChildren[0]?.props} />
        ) : (
          <h3
            className={`${font ? font : 'font-frutiger'} ${className ?? ''} font-bold color-ba-primary-black mb-4`}
          >
            {updatedChildren}
          </h3>
        );
      },
      [BLOCKS.HEADING_4]: (node: any, children: any) => {
        const updatedChildren = removeAdjacentBrElements(children);
        return updatedChildren?.length === 1 &&
          updatedChildren?.[0]?.type === 'code' ? (
          <code {...updatedChildren[0]?.props} />
        ) : (
          <h4
            className={`${font ? font : 'font-frutiger'} ${className ?? ''} font-bold color-ba-primary-black mb-3`}
          >
            {updatedChildren}
          </h4>
        );
      },
      [BLOCKS.HEADING_5]: (node: any, children: any) => {
        const updatedChildren = removeAdjacentBrElements(children);
        return updatedChildren?.length === 1 &&
          updatedChildren?.[0]?.type === 'code' ? (
          <code {...updatedChildren[0]?.props} />
        ) : (
          <h5
            className={`${font ? font : 'font-frutiger'} ${className ?? ''} font-bold color-ba-primary-black mb-2`}
          >
            {updatedChildren}
          </h5>
        );
      },
      [BLOCKS.HEADING_6]: (node: any, children: any) => {
        const updatedChildren = removeAdjacentBrElements(children);
        return updatedChildren?.length === 1 &&
          updatedChildren?.[0]?.type === 'code' ? (
          <code {...updatedChildren[0]?.props} />
        ) : (
          <h6
            className={`${font ? font : 'font-frutiger'} ${className ?? ''} font-bold color-ba-primary-black mb-1`}
          >
            {updatedChildren}
          </h6>
        );
      },
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
        const updatedChildren = removeAdjacentBrElements(children);
        return updatedChildren?.length === 1 &&
          updatedChildren?.[0]?.type === 'code' ? (
          <code {...updatedChildren[0]?.props} />
        ) : value?.content[value?.content.length - 1] === node &&
          updatedChildren?.length === 1 &&
          updatedChildren?.[0]?.type === 'br' ? null : (
          <p className={`${className ?? ''} color-ba-gray-900 leading-normal`}>
            {updatedChildren}
          </p>
        );
      },
      [BLOCKS.OL_LIST]: (node: any, children: any) => {
        return <ol className={`${className ?? ''}`}>{children}</ol>;
      },
      [BLOCKS.UL_LIST]: (node: any, children: any) => {
        return <ul className={`${className ?? ''}`}>{children}</ul>;
      },
      [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
        const filteredChildren = children?.filter(
          (childList: any, childIndex: number) =>
            !Array.isArray(childList?.props?.children) ||
            !childList?.props?.children.some(
              (child: any) => child?.type === 'br'
            )
        );
        return <li className={`${className ?? ''}`}>{filteredChildren}</li>;
      },
      [BLOCKS.TABLE]: (node: any, children: any) => {
        const [, ...remainingChildren] = children || [];
        return (
          <div className="overflow-x-auto">
            <table className={`${className ?? ''}  `}>
              <thead>{children[0]}</thead>
              <tbody>{remainingChildren}</tbody>
            </table>
          </div>
        );
      },
      [BLOCKS.TABLE_ROW]: (node: any, children: any) => {
        return <tr className={`${className ?? ''}`}>{children}</tr>;
      },
      [BLOCKS.TABLE_CELL]: (node: any, children: any) => {
        return <td className={`${className ?? ''}`}>{children}</td>;
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node: any, children: any) => {
        return <th className={`${className ?? ''}`}>{children}</th>;
      },
      [BLOCKS.QUOTE]: (node: any, children: any) => {
        return <blockquote>{children}</blockquote>;
      },
      [BLOCKS.HR]: (node: any, children: any) => {
        return <hr className={`${className ?? ''}`} />;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
        if (
          node?.data?.target?.sys?.contentType?.sys?.id === 'cta' &&
          isJv &&
          node?.data?.target?.fields?.buttonType === 'Primary'
        ) {
          node.data.target.fields.buttonType = 'Secondary';
          node.data.target.fields.bgColor = 'var(--ba-blue-600)';
        }
        return (
          <ComponentResolver
            className={`section-block ${className ?? ''} ${isJv && node?.data?.target?.fields?.buttonType === 'hyperlink' && `color-ba-blue-600`}`}
            data={node?.data?.target}
            key={node?.data?.target?.sys?.id}
          />
        );
      }
    }
  };
  const text = documentToReactComponents(value as Document, options);
  return (
    <div
      className={`${className ?? ''} contentful-richtext-group`}
      style={{ ...styles }}
    >
      {text}
    </div>
  );
};

CmsRichText.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  styles: PropTypes.object
};
