/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { CmsImageProps } from '@bayada/interfaces';
import { ServiceProps } from '../../resources-components/services-list';
import './case-study.scss';
import ArticleTags, { ArticleTag } from '../../resources-components/tags';
import { ImageAndItemProps } from 'apps/bayada/components/image-and-item/image-and-item';
import { TextAndItemProps } from 'apps/bayada/components/text-and-item/text-and-item';
import Banner from 'apps/bayada/components/banner/banner';
import ComponentResolver from 'apps/bayada/components/component-resolver/component-resolver';
import { convertObjectToArray } from 'apps/bayada/utils/helper';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Container } from '@mui/material';
import ShareBlock, {
  ShareBlockProps
} from 'apps/bayada/components/share-block/share-block';
import DynamicCarousel from 'apps/bayada/components/dynamic-carousel/dynamic-carousel';
import BioPage, {
  BioPageProps
} from 'apps/bayada/components/bio-page/bio-page';
import { useAppContext } from 'apps/bayada/context/app-context';
import { useEffect } from 'react';
import { getWrapperProps } from '@bayada/shared/ui-components';

/* eslint-disable-next-line */
export interface CaseStudiesProps {
  services: ServiceProps | null;
  title: string;
  overview?: string;
  thumbnailImage?: CmsImageProps | null;
  image: CmsImageProps | null;
  shareBlock: ShareBlockProps | null;
  tags?: ArticleTag[];
  carouselItemCount?: number;
  carouselLabel?: string;
  imageAndItem1?: ImageAndItemProps | null;
  textAndItem?: TextAndItemProps | null;
  imageAndItem2?: ImageAndItemProps | null;
  additionalContents?: [];
  readMoreButtonText?: string;
  readMoreAriaLabel?: string;
  readMoreIcon?: CmsImageProps | null;
  aboutTheAuthorLabel?: string;
  author?: BioPageProps | null;
  env: string;
}

/**
 * Renders a set of case studies with dynamic content blocks and author details.
 *
 * @param {CaseStudiesProps} props - The properties for the case studies component
 * @return {JSX.Element} The JSX element representing the rendered case studies component
 */
export function CaseStudies(props: CaseStudiesProps) {
  const { setIsProgressbarRequired } = useAppContext();
  useEffect(() => {
    setIsProgressbarRequired(true);
    return () => {
      setIsProgressbarRequired(false);
    };
  }, [setIsProgressbarRequired]);

  const { wrapperContainer, wrapperClassName } = getWrapperProps(props);
  const {
    services,
    title,
    overview,
    image,
    shareBlock,
    tags,
    carouselItemCount,
    carouselLabel,
    thumbnailImage,
    author,
    aboutTheAuthorLabel,
    readMoreButtonText,
    readMoreIcon,
    ...contentBlocks
  } = props || {};

  const contentBlocksArray = convertObjectToArray(contentBlocks);
  const inspectorProperties = useContentfulInspectorMode();

  return (
    <div className={'case-study-wrapper'}>
      <Banner
        backgroundImage={props?.image}
        internalName={''}
        heading={props?.title}
        subHeading={props?.overview}
        text={props?.services?.fullName}
      />
      {(contentBlocksArray as Array<unknown>)?.map((section: any, index) => {
        // Check if the section is an object
        if (typeof section === 'object') {
          // Return a div with inspector properties and a component resolver
          return (
            <div
              {...inspectorProperties({
                entryId: section?.sys?.id,
                fieldId: section?.fields?.internalName
              })}
              key={index}
            >
              <ComponentResolver
                wrapperContainer={wrapperContainer}
                wrapperClassName={wrapperClassName}
                className="page-section"
                data={section}
              />
            </div>
          );
        }
      })}

      {author && (
        <div className="w-full">
          <Container fixed className="relative mx-auto">
            <div className="flex flex-col">
              <div className="author-details">
                {aboutTheAuthorLabel && (
                  <h3
                    className="t-18-21 mb-6 font-frutiger font-bold"
                    aria-label="author-name"
                  >
                    {aboutTheAuthorLabel}
                  </h3>
                )}

                <div>
                  {author && <BioPage isArticleBioPage={true} {...author} />}
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}

      <div className="w-full border-t border-b border-solid border-ba-gray-200">
        <Container fixed className="relative mx-auto">
          <div className="grid grid-cols-12 gap-6 lg:gap-4 flex-col-reverse my-8">
            {tags && (
              <div className="col-span-12 lg:col-span-9 order-2 lg:order-1">
                <ArticleTags tags={tags} />
              </div>
            )}
            <div className="col-span-12 lg:col-span-3 order-1 lg:order-2 flex">
              <div className="flex flex-col lg:ml-auto">
                {shareBlock && <ShareBlock {...shareBlock} isVertical />}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {carouselLabel && (
        <Container fixed className="relative mx-auto mt-20">
          <h3
            className="t-18-21 mb-0 font-frutiger font-bold"
            aria-label="carousel-label"
          >
            {carouselLabel}
          </h3>
        </Container>
      )}
      <DynamicCarousel
        env={props?.env}
        type={'case studies'}
        services={[services]}
        tags={tags}
        numberOfSlides={carouselItemCount}
      />
    </div>
  );
}

export default CaseStudies;
