/* eslint-disable @typescript-eslint/no-explicit-any */
import invoke from 'lodash/invoke';
import { pageMap } from '../mapping';
import { ArticlesProps } from '../components/resources/types/articles/articles';
import {
  parseBioPage,
  parseCmsImage,
  parseShareBlockData
} from './component-data-parser';
import { ArticleTag } from '../components/resources/resources-components/tags';
import { CaseStudiesProps } from '../components/resources/types/case-study/case-study';
import { ResourcesListProps } from '../components/resources/resources-list/resources-list';
import { ServiceProps } from '../components/resources/resources-components/services-list';
import { DropDownOption } from 'libs/shared/ui-components/src/lib/dropdown-button/dropdown-button';
import { uuidv4 } from './helper';
import { NewsProps } from '../components/resources/types/news/news';
import { PartnerSuccessStoryProps } from '../components/resources/types/partner-success-story/partner-success-story';
import { WhitePapersProps } from '../components/resources/types/white-papers/white-papers';
import { WhitePaperPdfProps } from '../components/resources/resources-components/resources-page-content';

type components = keyof typeof pageMap;
export const pageDataPareserMap: { [key in components]?: any } = {
  articles: parseArticlesData,
  caseStudy: parseCaseStudyData,
  resourceListing: parseResourceListingData,
  news: parseNewsData,
  partnerSuccessStory: parsePartnerSuccessStoryData,
  whitepapers: parseWhitePapersData
};

export function parsepageData(
  contentTypeID: string,
  rowData: { [key: string]: unknown }
) {
  try {
    const pageData = invoke(pageDataPareserMap, contentTypeID, rowData);

    return pageData;
  } catch (error) {
    console.log(`parsepageData error for ${contentTypeID} ::: `, error);
  }
}

export function parseArticlesData(rowData?: any): ArticlesProps | null {
  if (!rowData) {
    return null;
  }

  const articlesData: ArticlesProps = {
    author: parseBioPage(rowData?.fields?.author),
    contents: rowData?.fields?.contents,
    date: rowData?.fields?.date,
    image: parseCmsImage(rowData?.fields?.image),
    services: parseServicesData(rowData?.fields?.services),
    shareBlock: parseShareBlockData(rowData?.fields?.shareBlock),
    title: rowData?.fields?.title?.trim(),
    recommendedCarouselItemCount: rowData?.fields?.recommendedCarouselItemCount,
    recommendedCarouselLabel: rowData?.fields?.recommendedCarouselLabel?.trim(),
    tags: rowData?.fields?.tags
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseArticleTags(item)),
    aboutTheAuthorLabel: rowData?.fields?.aboutTheAuthorLabel?.trim(),
    overview: rowData?.fields?.overview?.trim(),
    tagsLabel: rowData?.fields?.tagLabel?.trim(),
    thumbnailImage: parseCmsImage(rowData?.fields?.thumbnailImage),
    readMoreButtonText: rowData?.fields?.readMoreButtonText?.trim(),
    readMoreAriaLabel: rowData?.fields?.readMoreAriaLabel?.trim(),
    readMoreIcon: parseCmsImage(rowData?.fields?.readMoreIcon),
    env: rowData?.sys?.environment?.sys?.id
  };

  return articlesData;
}

export function parseArticleTags(rowData?: any): ArticleTag | null {
  if (!rowData) {
    return null;
  }

  const articleTag: ArticleTag = {
    articleTag: rowData?.fields?.articleTag?.trim(),
    sys: rowData?.sys,
    entryId: rowData?.sys?.id
  };

  return articleTag;
}

export function parseCaseStudyData(rowData?: any): CaseStudiesProps | null {
  if (!rowData) {
    return null;
  }

  const caseStudyProps: CaseStudiesProps = {
    image: parseCmsImage(rowData?.fields?.image),
    services: parseServicesData(rowData?.fields?.services),
    shareBlock: parseShareBlockData(rowData?.fields?.shareBlock),
    title: rowData?.fields?.title?.trim(),
    additionalContents: rowData?.fields?.additionalContents,
    imageAndItem1: rowData?.fields?.imageAndItem1,
    textAndItem: rowData?.fields?.textAndItem,
    imageAndItem2: rowData?.fields?.imageAndItem2,
    tags: rowData?.fields?.tags
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseArticleTags(item)),
    overview: rowData?.fields?.overview?.trim(),
    thumbnailImage: parseCmsImage(rowData?.fields?.thumbnailImage),
    carouselItemCount: rowData?.fields?.recommendedCarouselItemCount,
    carouselLabel: rowData?.fields?.recommendedCarouselLabel?.trim(),
    readMoreButtonText: rowData?.fields?.readMoreButtonText?.trim(),
    readMoreAriaLabel: rowData?.fields?.readMoreAriaLabel?.trim(),
    author: parseBioPage(rowData?.fields?.author),
    aboutTheAuthorLabel: rowData?.fields?.aboutTheAuthorLabel?.trim(),
    readMoreIcon: parseCmsImage(rowData?.fields?.readMoreIcon),
    env: rowData?.sys?.environment?.sys?.id
  };

  return caseStudyProps;
}

export function parseResourceListingData(
  rowData: any
): ResourcesListProps | null {
  if (!rowData) {
    return null;
  }

  const listData: ResourcesListProps = {
    bannerHeading: rowData?.fields?.bannerHeading?.trim(),
    type: rowData?.fields?.type?.toLowerCase(),
    categoryLabel: rowData?.fields?.servicesLabel?.trim(),
    clearAllLabel: rowData?.fields?.clearAllLabel?.trim(),
    loadMoreLabel: rowData?.fields?.loadMoreLabel?.trim(),
    noOfCardsReturned: rowData?.fields?.noOfCardsReturned,
    searchInputIcon: parseCmsImage(rowData?.fields?.searchInputIcon),
    searchInputLabel: rowData?.fields?.searchInputLabel?.trim(),
    showMoreLabel: rowData?.fields?.showMoreLabel?.trim(),
    sortByLabel: rowData?.fields?.sortByLabel?.trim(),
    sortByOptions: rowData?.fields?.sortByOptions?.map((option: string) => {
      const dropdownOption: DropDownOption = {
        active: option?.toLowerCase() === 'latest' ? true : false,
        label: option?.toLowerCase().trim(),
        value: option?.toLowerCase(),
        id: uuidv4()
      };
      return dropdownOption;
    }) || { active: true, label: 'latest', value: 'latest', id: uuidv4() },
    tagLabel: rowData?.fields?.tagLabel?.trim()
  };
  return listData;
}

export function parseServicesData(rowData: any): ServiceProps | null {
  if (!rowData) {
    return null;
  }
  const service: ServiceProps = {
    fullName: rowData?.fields?.fullName?.trim(),
    masterDataId: rowData?.fields?.masterDataId,
    icon: parseCmsImage(rowData?.fields?.icon),
    shortName: rowData?.fields?.shortName,
    sys: rowData?.sys,
    entryId: rowData?.sys?.id,
    priorityOrder: rowData?.fields?.priorityOrder
  };
  return service;
}

export function parseNewsData(rowData?: any): NewsProps | null {
  if (!rowData) {
    return null;
  }
  const newsData: NewsProps = {
    author: parseBioPage(rowData?.fields?.author),
    contents: rowData?.fields?.contents,
    date: rowData?.fields?.date,
    image: parseCmsImage(rowData?.fields?.image),
    services: parseServicesData(rowData?.fields?.services),
    shareBlock: parseShareBlockData(rowData?.fields?.shareBlock),
    title: rowData?.fields?.title?.trim(),
    recommendedCarouselItemCount: rowData?.fields?.recommendedCarouselItemCount,
    recommendedCarouselLabel: rowData?.fields?.recommendedCarouselLabel?.trim(),
    tags: rowData?.fields?.tags
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseArticleTags(item)),
    aboutTheAuthorLabel: rowData?.fields?.aboutTheAuthorLabel?.trim(),
    overview: rowData?.fields?.overview?.trim(),
    tagsLabel: rowData?.fields?.tagsLabel?.trim(),
    thumbnailImage: parseCmsImage(rowData?.fields?.thumbnailImage),
    readMoreButtonText: rowData?.fields?.readMoreButtonText?.trim(),
    readMoreAriaLabel: rowData?.fields?.readMoreAriaLabel?.trim(),
    readMoreIcon: parseCmsImage(rowData?.fields?.readMoreIcon),
    form: rowData?.fields?.form?.fields,
    env: rowData?.sys?.environment?.sys?.id
  };

  return newsData;
}

export function parsePartnerSuccessStoryData(
  rowData: any
): PartnerSuccessStoryProps | null {
  if (!rowData) {
    return null;
  }
  const partnerSuccessStorydata: PartnerSuccessStoryProps = {
    services: parseServicesData(rowData?.fields?.services),
    thumbnailImage: parseCmsImage(rowData?.fields?.thumbnailImage),
    title: rowData?.fields?.title?.trim(),
    author: parseBioPage(rowData?.fields?.author),
    aboutTheAuthorLabel: rowData?.fields?.aboutTheAuthorLabel?.trim(),
    date: rowData?.fields?.date,
    bannerSubHeading: rowData?.fields?.bannerSubheading?.trim(),
    overview: rowData?.fields?.overview?.trim(),
    image: parseCmsImage(rowData?.fields?.image),
    partnershipDetails: rowData?.fields?.partnershipDetails?.trim(),
    textBlock: rowData?.fields?.textBlock,
    horizontalSeperator1: rowData?.fields?.horizontalSeparator,
    textAndItem1: rowData?.fields?.textAndItem1,
    horizontalSeperator2: rowData?.fields?.horizontalSeparator2,
    textAndItem2: rowData?.fields?.textAndItem2,
    testimonial: rowData?.fields?.testimonial,
    imageAndItem: rowData?.fields?.imageAndItem,
    tagsLabel: rowData?.fields?.tagsLabel?.trim(),
    tags: rowData?.fields?.tags
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseArticleTags(item)),
    additionalContents: rowData?.fields?.additionalComponents,
    shareBlock: parseShareBlockData(rowData?.fields?.shareBlock),
    recommendedCarouselItemCount: rowData?.fields?.recommendedCarouselItemCount,
    recommendedCarouselLabel: rowData?.fields?.recommendedCarouselLabel?.trim(),
    readMoreButtonText: rowData?.fields?.readMoreButtonText?.trim(),
    readMoreAriaLabel: rowData?.fields?.readMoreAriaLabel?.trim(),
    readMoreIcon: parseCmsImage(rowData?.fields?.readMoreIcon),
    env: rowData?.sys?.environment?.sys?.id
  };
  return partnerSuccessStorydata;
}

export function parseWhitePapersData(rowData?: any): WhitePapersProps | null {
  if (!rowData) return null;

  const data: WhitePapersProps = {
    internalName: rowData?.fields?.internalName,
    thumbnailImage: parseCmsImage(rowData?.fields?.thumbnailImage),
    author: parseBioPage(rowData?.fields?.author),
    aboutTheAuthorLabel: rowData?.fields?.aboutTheAuthorLabel?.trim(),
    overview: rowData?.fields?.overview?.trim(),
    contents: rowData?.fields?.contents,
    date: rowData?.fields?.date,
    image: parseCmsImage(rowData?.fields?.image),
    services: parseServicesData(rowData?.fields?.services),
    shareBlock: parseShareBlockData(rowData?.fields?.shareBlock),
    title: rowData?.fields?.title?.trim(),
    carouselItemCount: rowData?.fields?.recommendedCarouselItemCount,
    carouselLabel: rowData?.fields?.recommendedCarouselLabel?.trim(),
    whitePaperPdf: parseWhitePaperPdfProps(rowData?.fields?.whitepaperPdf),
    downloadText: rowData?.fields?.downloadText?.trim(),
    tags: rowData?.fields?.tags
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseArticleTags(item)),
    tagsLabel: rowData?.fields?.tagsLabel?.trim(),
    readMoreButtonText: rowData?.fields?.readMoreButtonText?.trim(),
    readMoreAriaLabel: rowData?.fields?.readMoreAriaLabel?.trim(),
    readMoreIcon: parseCmsImage(rowData?.fields?.readMoreIcon),
    env: rowData?.sys?.environment?.sys?.id
  };

  return data;
}

export function parseWhitePaperPdfProps(
  rowData?: any
): WhitePaperPdfProps | null {
  if (!rowData) return null;

  const data: WhitePaperPdfProps = {
    title: rowData?.fields?.title?.trim(),
    fileName: rowData?.fields?.file?.fileName?.trim(),
    fileUrl: rowData?.fields?.file?.url
  };

  return data;
}
