import React, { useCallback, useEffect, useState } from 'react';
import { CmsImageProps, Sys } from '@bayada/interfaces';
import { contentfulClient } from 'apps/bayada/config/contentful-client';
import { Checkbox } from '@mui/material';
import { Icon } from '@bayada/shared/ui-components';

const contentful = contentfulClient();
export type ServiceListProps = {
  categoryLabel?: string;
  clearAllLabel?: string;
  showMoreLabel?: string;
  onSelect: (option: ServiceProps[]) => void;
};

export type ServiceProps = {
  masterDataId: string;
  shortName?: string;
  fullName?: string;
  icon?: CmsImageProps | null;
  sys?: Sys;
  checked?: boolean;
  entryId?: string;
  priorityOrder?: string;
};
/**
 * This code defines a React component called ServicesList that displays a list of services with
 * checkboxes for selection.
 * @property {string} categoryLabel - The `categoryLabel` property in the `ServiceListProps` type is
 * used to specify the label for the category of services displayed in the component. It defaults to
 * 'Services' if not provided when using the component.
 * @property {string} clearAllLabel - The `clearAllLabel` property in the `ServicesList` component is
 * used to specify the label text for the "Clear All" button that allows users to clear all selected
 * services. It provides a way for users to easily deselect all the services they have previously
 * selected.
 * @property {string} showMoreLabel - The `showMoreLabel` property in the `ServiceListProps` type is
 * used to specify the label for a button that allows users to show more services in the list. This
 * button is typically used when there are more services available than currently displayed, and
 * clicking on it will reveal additional services in the
 * @property onSelect - The `onSelect` property in the `ServiceListProps` type is a function that takes
 * an array of `ServiceProps` as its argument. This function is called when a user selects or deselects
 * a service in the list. It is responsible for updating the selected services based on the user's
 */
export const ServicesList: React.FC<ServiceListProps> = ({
  categoryLabel = 'Services',
  clearAllLabel = 'Clear All',
  onSelect,
  showMoreLabel = ''
}) => {
  const [services, setServicesList] = useState<ServiceProps[]>();
  const [itemsToShow, setItemsToShow] = useState<number>(5);

  const getServicesList = useCallback(async () => {
    try {
      const response = await contentful?.getEntries({
        content_type: 'services',
        limit: 500
      });
      const servicesList = response?.items;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const parsedData = servicesList?.map((item: { [key: string]: any }) => {
        const service: ServiceProps = {
          fullName: item?.fields?.fullName,
          masterDataId: item?.fields?.masterDataId,
          icon: item?.fields?.icon,
          shortName: item?.fields?.shortName,
          sys: item?.sys,
          checked: false
        };
        return service;
      });
      setServicesList(parsedData);
    } catch (error) {
      console.log(error, '::: getServicesList error');
    }
  }, []);

  const handleCheckboxChange = (data: ServiceProps) => {
    if (services) {
      const currentState = [...services]?.map((item) => {
        if (item?.masterDataId === data?.masterDataId) {
          item.checked = !item.checked;
        }
        return item;
      });
      setServicesList(currentState);
      const selectedServices = currentState?.filter(
        (option) => option?.checked
      );
      onSelect(selectedServices);
    }
  };

  const handleClearAllClick = () => {
    if (services) {
      const currentState = [...services]?.map((item) => {
        if (item.checked) {
          item.checked = !item.checked;
        }
        return item;
      });
      setServicesList(currentState);
      onSelect([]);
    }
  };

  const handleShowMoreClick = () => {
    if (services) {
      setItemsToShow(services?.length);
    }
  };

  useEffect(() => {
    getServicesList();
  }, [getServicesList]);
  return (
    <div className=" flex flex-col gap-4">
      <div className="flex  justify-between">
        {categoryLabel && (
          <h2
            className="t-14 font-frutiger font-semibold"
            aria-label="category-label"
          >
            {categoryLabel}
          </h2>
        )}
        {clearAllLabel && (
          <h2
            className="t-13  cursor-pointer text-right font-semibold color-ba-gray-425"
            onClick={() => handleClearAllClick()}
            aria-label="clear-label"
          >
            {clearAllLabel}
          </h2>
        )}
      </div>
      <div className="flex flex-col gap-4">
        {services?.map((data, index) =>
          index < itemsToShow ? (
            <label key={index} className={`flex`}>
              <Checkbox
                checked={data?.checked}
                onClick={() => handleCheckboxChange(data)}
                className="service-checkbox"
                icon={
                  <Icon
                    iconName=""
                    className="svg-icon icon-24 rounded-full border border-solid border-gray-300 bg-[#FFFFFF]"
                  ></Icon>
                }
                checkedIcon={
                  <div className="icon-24 flex items-center justify-center  rounded-full bg-ba-green-450 p-1">
                    <Icon
                      iconName="checkIcon"
                      className="svg-icon icon-12"
                    ></Icon>
                  </div>
                }
              />
              <span className="t-13 mt-[2px] font-normal ml-1">
                {data.fullName}
              </span>
            </label>
          ) : null
        )}
        {itemsToShow !== services?.length && (
          <button
            aria-label={'show-more-services-button'}
            onClick={handleShowMoreClick}
            className="mt-2 flex items-center justify-start border-none t-13  font-semibold color-ba-primary-red underline cursor-pointer"
          >
            {showMoreLabel}
          </button>
        )}
      </div>
    </div>
  );
};
export default ServicesList;
