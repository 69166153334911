import { Icon } from '@bayada/shared/ui-components';
import { Button } from '@mui/material';
import { toggleButtonVariant } from './resources-list';

export interface ResourcesButtonVariantProps {
  buttonType: toggleButtonVariant;
  onClick: () => void;
}
/**
 * This component is responsible for rendering the Resources filter button.
 * @param buttonType The type of button to render, currently there are two types: 'filter' and 'back'.
 * @param onClick The function to call when the button is clicked.
 * @returns The JSX Element to render.
 */
const ResourcesButtonVariant: React.FC<ResourcesButtonVariantProps> = ({
  buttonType,
  onClick
}) => {
  switch (buttonType) {
    case 'filter':
      return (
        <Button
          variant="outlined"
          aria-label={'filter-button'}
          color="secondary"
          disableFocusRipple
          disableTouchRipple
          className="icon-only"
          sx={{
            boxShadow: '0px 0px 4px 0px #00000033',
            height: '58px',
            border: '0',
            ':hover': {
              border: '0'
            }
          }}
          endIcon={
            <Icon
              iconName="filterIcon"
              className="svg-icon icon-24 flex items-center justify-center"
            />
          }
          onClick={onClick}
        ></Button>
      );
    case 'back':
      return (
        <button
          aria-label={'filter-drawer-back-button'}
          onClick={onClick}
          className="mr-4 flex items-center justify-center border-none p-"
        >
          <Icon
            iconName="rightArrow"
            className="svg-icon icon-24 flex items-center justify-center rotate-180"
          />
        </button>
      );
    default:
      return null;
  }
};

export default ResourcesButtonVariant;
