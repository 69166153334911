import { CmsImage } from '@bayada/shared/ui-components';
import { FormControl, TextField } from '@mui/material';
import React, { useState } from 'react';
import { CmsImageProps } from '@bayada/interfaces';

export type ResourcesSearchBarProps = {
  searchInputLabel?: string;
  searchInputIcon?: CmsImageProps | null;
  onChange: (searchTerm: string) => void;
};

/**
 * @description - Render a search bar component with a label, optional icon, and input field
 * that triggers the onChange callback when the value changes.
 *
 * @param {string} searchInputLabel -  The label text for the search input field. Defaults to 'search'.
 * @param {CmsImageProps} searchInputIcon - An optional icon to display on the search input field.
 * @param  {(searchTerm: string | null) => void} onChange - The callback function to be called when the search input value changes.
 * @returns - The JSX element to render.
 */
const ResourcesSearchBar: React.FC<ResourcesSearchBarProps> = ({
  searchInputLabel = 'search',
  searchInputIcon,
  onChange
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  /**
   * @description - Handles changes to the input search field and updates the local search query state and the passed on to the onChange callback.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The event triggered when search input value changes.
   */
  const handleInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e?.target?.value;
    setSearchQuery(newSearchTerm);
    onChange(newSearchTerm);
  };

  return (
    <div className="flex items-center flex-1 ">
      <FormControl
        className={`icon-text-field icon-right standardInputField relative flex items-center rounded-md`}
      >
        <div className="search-icon right-0 absolute top-0 z-10 flex h-full items-center justify-center">
          {searchInputIcon && (
            <CmsImage
              width={24}
              height={24}
              {...searchInputIcon}
              className="mr-4"
            />
          )}
        </div>
        <TextField
          id="input-with-icon-textfield2"
          label={searchInputLabel}
          variant="standard"
          value={searchQuery}
          className="app-icon-field"
          onChange={handleInputSearch}
        />
      </FormControl>
    </div>
  );
};

export default ResourcesSearchBar;
