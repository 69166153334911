/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { TextBlockProps } from 'apps/bayada/components/text-block/text-block';
import {
  SeperatorBlockProps,
  getWrapperProps
} from '@bayada/shared/ui-components';
import { TextAndItemProps } from 'apps/bayada/components/text-and-item/text-and-item';
import { TestimonialProps } from 'apps/bayada/components/testimonial/testimonial';
import { ImageAndItemProps } from 'apps/bayada/components/image-and-item/image-and-item';
import ArticleTags, { ArticleTag } from '../../resources-components/tags';

import { CmsImageProps } from '@bayada/interfaces';
import BioPage, {
  BioPageProps
} from 'apps/bayada/components/bio-page/bio-page';
import { ServiceProps } from '../../resources-components/services-list';

import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import ComponentResolver from 'apps/bayada/components/component-resolver/component-resolver';
import ShareBlock, {
  ShareBlockProps
} from 'apps/bayada/components/share-block/share-block';
import { convertObjectToArray } from 'apps/bayada/utils/helper';
import Banner from 'apps/bayada/components/banner/banner';
import Container from '@mui/material/Container';
import DynamicCarousel from 'apps/bayada/components/dynamic-carousel/dynamic-carousel';
import { useAppContext } from 'apps/bayada/context/app-context';
import { useEffect } from 'react';

/* eslint-disable-next-line */
export interface PartnerSuccessStoryProps {
  services: ServiceProps | null;
  thumbnailImage?: CmsImageProps | null;
  author?: BioPageProps | null;
  date: string;
  title: string;
  bannerSubHeading?: string;
  overview?: string;
  image?: CmsImageProps | null;
  partnershipDetails?: string;
  textBlock?: TextBlockProps | null;
  horizontalSeperator1?: SeperatorBlockProps | null;
  textAndItem1?: TextAndItemProps | null;
  horizontalSeperator2?: SeperatorBlockProps | null;
  textAndItem2?: TextAndItemProps | null;
  testimonial?: TestimonialProps | null;
  imageAndItem?: ImageAndItemProps | null;
  tagsLabel?: string;
  tags?: ArticleTag[];
  additionalContents?: [];
  shareBlock?: ShareBlockProps | null;
  recommendedCarouselItemCount?: number;
  recommendedCarouselLabel?: string;
  readMoreButtonText?: string;
  readMoreAriaLabel?: string;
  readMoreIcon?: CmsImageProps | null;
  aboutTheAuthorLabel?: string;
  env: string;
}

/**
 * PartnerSuccessStory Component
 * This component renders the Partner Success Story Page.
 * It has several sections:
 * 1. Banner: The banner component will render an image, a heading, and a subheading.
 * 2. Content blocks: This section will render content blocks based on the content blocks passed in as props.
 *    The content blocks will be rendered with the ComponentResolver component, which will use the ComponentResolver
 *    to determine the type of component to render. For example, if the content block is a TextBlock, the TextBlock
 *    component will be rendered.
 * 3. Author details: This section renders the author details passed in as props, using the BioPage component.
 * 4. Share block: This section renders the share block passed in as props, using the ShareBlock component.
 * 5. Recommended carousel: This section renders a carousel of recommended Partner Success Stories, using the DynamicCarousel
 *    component. The number of slides to display is determined by the recommendedCarouselItemCount prop.
 */
export function PartnerSuccessStory(props: PartnerSuccessStoryProps) {
  const { setIsProgressbarRequired } = useAppContext();

  useEffect(() => {
    setIsProgressbarRequired(true);
    return () => {
      setIsProgressbarRequired(false);
    };
  }, [setIsProgressbarRequired]);

  const { wrapperContainer, wrapperClassName } = getWrapperProps(props);
  const {
    services,
    title,
    overview,
    image,
    shareBlock,
    tags,
    recommendedCarouselItemCount,
    recommendedCarouselLabel,
    author,
    aboutTheAuthorLabel,
    thumbnailImage,
    readMoreIcon,
    readMoreButtonText,
    bannerSubHeading,
    partnershipDetails,
    ...contentBlocks
  } = props || {};

  const contentBlocksArray = convertObjectToArray(contentBlocks);
  const inspectorProperties = useContentfulInspectorMode();
  return (
    <div className={'partner-success-story-wrapper'}>
      <Banner
        image={props?.image}
        textColor={'black'}
        internalName={''}
        heading={title}
        subHeading={bannerSubHeading}
        text={partnershipDetails}
      />
      {(contentBlocksArray as Array<unknown>)?.map((section: any, index) => {
        // Check if the section is an object
        if (typeof section === 'object') {
          // Return a div with inspector properties and a component resolver
          return (
            <div
              {...inspectorProperties({
                entryId: section?.sys?.id,
                fieldId: section?.fields?.internalName
              })}
              key={index}
            >
              <ComponentResolver
                wrapperContainer={wrapperContainer}
                wrapperClassName={wrapperClassName}
                className="page-section"
                data={section}
              />
            </div>
          );
        }
      })}

      <div className="grid grid-cols-12 gap-6 lg:gap-4 flex-col-reverse">
        <div className="col-span-12">
          <Container fixed className="relative mx-auto">
            <div className="flex flex-col" aria-label="about-author-container">
              {author && (
                <div className="author-details">
                  {aboutTheAuthorLabel && (
                    <h3 className="t-18-21 mb-6 font-frutiger font-bold">
                      {aboutTheAuthorLabel}
                    </h3>
                  )}

                  {author && (
                    <div>
                      <BioPage isArticleBioPage={true} {...author} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Container>
        </div>
        {(tags || shareBlock) && (
          <div className="col-span-12 flex border-t border-b border-solid border-ba-gray-200 py-8">
            <Container fixed className="relative mx-auto">
              <div className="grid grid-cols-12 gap-6 lg:gap-4 flex-col-reverse">
                {tags && (
                  <div className="col-span-12 lg:col-span-9 order-2 lg:order-1">
                    <ArticleTags tags={tags} />
                  </div>
                )}

                {shareBlock && (
                  <div className="col-span-12 lg:col-span-3 order-1 lg:order-2 flex">
                    <ShareBlock {...shareBlock} isVertical />
                  </div>
                )}
              </div>
            </Container>
          </div>
        )}
      </div>

      {recommendedCarouselLabel && (
        <Container fixed className="relative mx-auto mt-20">
          <h3
            className="t-18-21 mb-0 font-frutiger font-bold"
            aria-label="recommended-label"
          >
            {recommendedCarouselLabel}
          </h3>
        </Container>
      )}
      <DynamicCarousel
        env={props?.env}
        type={'partner success stories'}
        services={[services]}
        tags={tags}
        numberOfSlides={recommendedCarouselItemCount}
      />
    </div>
  );
}

export default PartnerSuccessStory;
