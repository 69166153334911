'use client';

import { Container, Grid } from '@mui/material';
import './articles.scss';
import { BioPageProps } from 'apps/bayada/components/bio-page/bio-page';
import { CmsRichTextProps } from '../../../cms-rich-text/cms-rich-text';
import { CmsImageProps } from '@bayada/interfaces';
import { ServiceProps } from '../../resources-components/services-list';
import ArticleTags, { ArticleTag } from '../../resources-components/tags';
import { ShareBlockProps } from 'apps/bayada/components/share-block/share-block';
import DynamicCarousel from 'apps/bayada/components/dynamic-carousel/dynamic-carousel';
import { RightSidebar } from '../../resources-components/resources-right-sidebar';
import { PageContent } from '../../resources-components/resources-page-content';
import { useEffect } from 'react';
import { useAppContext } from 'apps/bayada/context/app-context';

export type ArticlesProps = {
  services: ServiceProps | null;
  title: string;
  overview?: string;
  thumbnailImage?: CmsImageProps | null;
  author: BioPageProps | null;
  date: string;
  image: CmsImageProps | null;
  shareBlock: ShareBlockProps | null;
  tags?: ArticleTag[];
  contents: CmsRichTextProps;
  recommendedCarouselItemCount?: number;
  recommendedCarouselLabel?: string;
  aboutTheAuthorLabel?: string;
  tagsLabel?: string;
  readMoreButtonText?: string;
  readMoreAriaLabel?: string;
  readMoreIcon?: CmsImageProps | null;
  env: string;
};

/**
 * The Articles component is the main component for rendering a single article page.
 * It takes in an ArticlesProps interface as a prop.
 *
 * The component is composed of the following parts:
 * 1. PageContent - renders the article's contents, author, date, image, title,
 *    recommended carousel label, and share block.
 * 2. RightSidebar - renders the article's tags and share block. This is hidden on
 *    screens smaller than lg.
 * 3. ArticleTags - renders the article's tags in a list format. This is shown only on
 *    screens smaller than lg.
 * 4. DynamicCarousel - renders a carousel of articles based on the article's services
 *    and recommended carousel item count.
 *
 * The component also has the following responsibilities:
 * 1. It renders the recommended carousel label on top of the carousel.
 * 2. It renders the ArticleTags component below the carousel on screens smaller than lg.
 */
export const Articles: React.FC<ArticlesProps> = (props) => {
  const { setIsProgressbarRequired } = useAppContext();

  useEffect(() => {
    setIsProgressbarRequired(true);
    return () => {
      setIsProgressbarRequired(false);
    };
  }, []);

  const {
    author,
    contents,
    date,
    image,
    services,
    shareBlock,
    title,
    recommendedCarouselItemCount,
    recommendedCarouselLabel,
    tagsLabel,
    aboutTheAuthorLabel,
    tags
  } = props || {};

  return (
    <div className="relative pt-10 flex flex-col w-full">
      <Container fixed className="relative mx-auto">
        <Grid container spacing={2}>
          <Grid item lg={9}>
            <PageContent
              author={author}
              contents={contents}
              date={date}
              image={image}
              title={title}
              carouselItemCount={recommendedCarouselItemCount}
              carouselLabel={recommendedCarouselLabel}
              shareBlock={shareBlock}
              aboutTheAuthorLabel={aboutTheAuthorLabel}
            />
          </Grid>
          <Grid item lg={3} className="hidden lg:block">
            <RightSidebar
              tags={tags}
              tagsLabel={tagsLabel}
              shareBlock={shareBlock}
            />
          </Grid>
        </Grid>
        {tags && tags?.length > 0 && (
          <div className="col-span-12 lg:col-span-9 order-2 lg:order-1  lg:hidden mb-6">
            <ArticleTags tags={tags} title={tagsLabel} />
          </div>
        )}
      </Container>
      {recommendedCarouselLabel && (
        <Container fixed className="relative mx-auto mt-20">
          <h3
            className="t-18-21 mb-0 font-frutiger font-bold"
            aria-label="recommended-label"
          >
            {recommendedCarouselLabel}
          </h3>
        </Container>
      )}

      <DynamicCarousel
        env={props?.env}
        type={'articles'}
        services={[services]}
        tags={tags}
        numberOfSlides={recommendedCarouselItemCount}
      />
    </div>
  );
};

export default Articles;
