'use client';

import { Button } from '@mui/material';
import { Icon } from '@bayada/shared/ui-components';
import { useCallback } from 'react';
import { WhitePaperPdfProps } from './resources-page-content';

export interface DownloadButtonProps {
  buttonLabel: string;
  whitePaperPdf?: WhitePaperPdfProps | null;
  isOutlinedButton?: boolean;
  className?: string;
  sx?: React.CSSProperties;
}

export const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
  const {
    buttonLabel,
    whitePaperPdf,
    className,
    isOutlinedButton = false,
    sx
  } = props || {};

  /*
   * The `handleOnClick` constant in the `DownloadButton` component is a memoized callback function created
   * using the `useCallback` hook in React. It is responsible for handling the click event on the button.
   * Here's a breakdown of what it does:
   */
  const handleOnClick = useCallback(() => {
    if (whitePaperPdf?.fileUrl) {
      fetchFile(whitePaperPdf?.fileUrl);
    } else {
      console.log('no files attached');
    }
  }, [whitePaperPdf]);

  /**
   * The function `fetchFile` fetches a file from a given URL, creates a temporary URL for the file, and
   * initiates a download of the file.
   * @param {any} url - The `url` parameter in the `fetchFile` function is the URL of the file that you
   * want to fetch and download.
   */
  function fetchFile(url: string) {
    fetch(url)
      .then((res) => res.blob())
      .then((file) => {
        const tempUrl = URL.createObjectURL(file);
        const aTag = document.createElement('a');
        aTag.href = tempUrl;
        aTag.download = url.replace(/^.*[\\/]/, '');
        document.body.appendChild(aTag);
        aTag.click();
        URL.revokeObjectURL(tempUrl);
        aTag.remove();
      });
  }

  return (
    <Button
      variant={isOutlinedButton ? 'outlined' : 'text'}
      color="secondary"
      disableFocusRipple
      className={`icon-btn-movement ${className ?? ''}`}
      aria-label={`${whitePaperPdf?.fileName ?? 'Pdf'} download button`}
      style={{
        ...sx
      }}
      endIcon={
        <Icon
          iconName="download"
          className="svg-icon icon-24 moveRightIcon flex items-center justify-center"
        ></Icon>
      }
      onClick={() => handleOnClick()}
    >
      <span className="t-15 font-frutiger font-medium leading-snug">
        {buttonLabel}
      </span>
    </Button>
  );
};
