import { DropdownButton, Icon } from '@bayada/shared/ui-components';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { DropDownOption } from 'libs/shared/ui-components/src/lib/dropdown-button/dropdown-button';
import { useState } from 'react';

export interface ResourcesSortFilterProps {
  isDropdown?: boolean;
  sortByLabel: string;
  sortByOptions: DropDownOption[];
  onButtonClick: (options: DropDownOption) => void;
}

/**
 * Component that renders sort and filter options for resources list page.
 * This component receives two props:
 *   1. isDropdown: boolean prop indicating whether to render the dropdown or radio buttons.
 *   2. sortByOptions: an array of objects with shape { id: string, label: string }
 *      which are used to populate the sort and filter options.
 *
 * Depending on the value of isDropdown prop, this component renders either a dropdown with all
 * the sort and filter options or a set of radio buttons.
 *
 * The component also expects a onButtonClick function prop which is called with the selected sort or filter option when the user clicks on it.
 */
const ResourcesSortFilter: React.FC<ResourcesSortFilterProps> = ({
  isDropdown = false,
  sortByLabel,
  sortByOptions = [],
  onButtonClick
}) => {
  const [selectedSortOption, setSelectedSortOption] = useState<DropDownOption>(
    sortByOptions[0]
  );

  /**
   * Handles the Dropdown button click event and updates the selected sort option state.
   * If a valid option is provided, it updates the selected sort option state and invokes the `onButtonClick` callback.
   *
   * @param {DropDownOption} option - The option selected from the dropdown menu.
   * @returns {void}
   */
  const handleButtonClick = (option: DropDownOption) => {
    setSelectedSortOption(option);
    if (option) {
      onButtonClick(option);
    }
  };

  /**
   * Handles change event on any of the radio buttons.
   * Finds the selected option from the sortByOptions prop array using the selectedValue and calls handleButtonClick
   * with the selected option.
   */
  const handleRadioButtonClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e?.target?.value;
    const selectedOption = sortByOptions?.find(
      (option) => option?.id === selectedValue
    );
    if (selectedOption) {
      handleButtonClick(selectedOption);
    }
  };

  return isDropdown ? (
    <div className="sm:flex justify-end items-center gap-3 hidden ">
      <span className="t-13 color-ba-gray-900" aria-label="sort-label">
        {sortByLabel}
      </span>
      <DropdownButton
        triggerClassName="t-13 leading-snug"
        sx={{
          border: '1px solid #D8D8DA',
          padding: '10px',
          width: '100px'
        }}
        items={sortByOptions}
        onSelect={(option) => handleButtonClick(option)}
      />
    </div>
  ) : (
    <div>
      <h2 className="t-14 font-frutiger font-semibold">{sortByLabel}</h2>
      <div className="flex flex-col">
        <FormControl
          sx={{
            '&.MuiFormControl-root': {
              paddingLeft: '5px !important'
            }
          }}
        >
          <RadioGroup
            role="radio"
            aria-label={`${sortByLabel}-radio-btn-group`}
            value={selectedSortOption?.id}
            onChange={handleRadioButtonClick}
            sx={{
              '&.MuiRadio-root': {
                color: '#FFFFFF',
                paddingBottom: '0px',
                paddingTop: '0px'
              },
              '& .MuiFormControlLabel-label': {
                fontSize: '13px !important',
                fontWeight: 'normal'
              },
              '& .MuiRadio-root:hover': {
                backgroundColor: 'transparent' // Change the background color on hover
              }
            }}
          >
            {sortByOptions?.map((item: DropDownOption, key: number) => (
              <FormControlLabel
                key={key}
                value={item?.id}
                aria-label="form-control-label"
                control={
                  <Radio
                    icon={
                      <Icon
                        iconName=""
                        className="svg-icon icon-24 rounded-full border border-solid border-gray-300 bg-white"
                      ></Icon>
                    }
                    checkedIcon={
                      <div className="icon-24 flex items-center justify-center rounded-full bg-ba-green-450 p-[1px] border-ba-green-450">
                        <Icon
                          iconName=""
                          className="svg-icon icon-20 rounded-[50%] border-[3px] border-solid border-white bg-ba-green-450"
                        ></Icon>
                      </div>
                    }
                  />
                }
                label={item?.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default ResourcesSortFilter;
