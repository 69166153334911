'use client';

import { throttle } from 'lodash';
import { useState, useEffect } from 'react';
import ShareBlock, {
  ShareBlockProps
} from 'apps/bayada/components/share-block/share-block';
import ArticleTags, { ArticleTag } from './tags';
import { DownloadButton } from './resources-download-button';
import { WhitePaperPdfProps } from './resources-page-content';

export interface RightSidebarProps {
  shareBlock?: ShareBlockProps | null;
  tags?: ArticleTag[];
  tagsLabel?: string;
  whitePaperPdf?: WhitePaperPdfProps | null;
  buttonLabel?: string;
}
/* The code provided is a TypeScript React component called `RightSidebar`. This component is
responsible for rendering a right sidebar section on a webpage. */
export const RightSidebar: React.FC<RightSidebarProps> = (props) => {
  const [isSticky, setIsSticky] = useState(false);
  const {
    shareBlock,
    tags = [],
    whitePaperPdf,
    buttonLabel = '',
    tagsLabel
  } = props || {};

  useEffect(() => {
    const handleScroll = throttle(() => {
      const triggerBlock = document.getElementById('trigger-block-element');
      if (triggerBlock) {
        const triggerBlockRect = triggerBlock.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        setIsSticky(
          triggerBlockRect.top >= viewportHeight || triggerBlockRect.bottom <= 0
        );
      }
    }, 200);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`right-sidebar relative ${isSticky ? 'sticky' : ''}`}>
      <div className="pt-8">
        <div className="pb-6">
          {shareBlock && <ShareBlock {...shareBlock} />}
        </div>
        {(buttonLabel || whitePaperPdf) && (
          <DownloadButton
            isOutlinedButton
            buttonLabel={buttonLabel}
            whitePaperPdf={whitePaperPdf}
          />
        )}
        <div className="mb-4 mt-4 hidden flex-wrap gap-2 md:flex">
          {tags && tags?.length > 0 && (
            <ArticleTags tags={tags} title={tagsLabel} />
          )}
        </div>
      </div>
    </div>
  );
};
